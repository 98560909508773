<template>
    <div class="card asset-preview in-selection selectable">
        <div
            v-if="canViewAsset()"
            class="thumbnail"
            style="cursor: pointer"
            @click="onThumbnailClick()"
        >
            <img :src="image_src" :alt="title">
        </div>
        <div v-else class="thumbnail">
            <img :src="image_src" :alt="title">
        </div>

        <div class="card-body" style="position: relative">
            <div
                v-if="canViewAsset()"
                class="title mb-0"
                :id="prefix + 'asset-title' + id"
                @click="viewAsset()"
                style="cursor: pointer"
            >
                {{ short_title }}
            </div>
            <div v-else class="title mb-0" :id="prefix + 'asset-title' + id">
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="prefix + 'asset-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <!--<div>
                <span
                    :class="(!downloadOnlyAccess() ? 'asset-info' : 'asset-info stay')"
                    :id="prefix + 'asset-info' + id"
                    :style="assetInfoStyle()"
                >
                    <span v-if="$root.isLoggedIn()" class="float-right" style="position: relative; top: 2px">
                        {{ id }}
                    </span>
                    <span v-if="data.suspended" class="badge badge-danger font-size-11">SUSPENDED</span>
                    <span v-else>
                        <span v-if="isTemplate()" class="badge bg-secondary text-secondary font-size-11">VARIABLE</span>
                        <span v-if="!isTemplate() && data">
                            <span
                                v-if="extension && (!data.print_order || data.downloadable)"
                                :class="'badge badge-primary font-size-11'
                                    + (data.archived ? ' d-lg-inline-block d-none' : '')"
                                style="text-transform: uppercase"
                            >{{ extension }}</span>
                            <span v-if="data">
                                <span
                                    v-if="data.print_order"
                                    class="badge bg-secondary text-secondary font-size-11"
                                >
                                    {{ data.downloadable ? 'ACTIONS' : 'ORDER ITEM' }}
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'in_share', 'email'],
        data () {
            return {
                id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                extension: null,
                file_type: null,
                archived: false,
                restricted: false,
                prefix: 'related-',
                asset_menu_mobile_full: false,
                adding_to_collection: false,
                add_to_collection_errors: false,
                added_to_collection: null,
                removing_from_collection: false,
                downloading: false,
                showing_template_options: false,
                ordering: false,
                order_item: null
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                vm.short_title = vm.title;
                setTimeout(function () {
                    vm.fitTitle();
                }, 500);
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.id;
                    this.title = data.title;
                    this.short_title = data.title;
                    this.extension = data.file_extension;
                    this.file_type = data.file_type;
                    this.archived = data.archived;
                    this.restricted = data.restricted;
                    if (this.image_src == '/images/file-icon.png') {
                        this.getPreviewImage();
                    }
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            },
            getPreviewImage: function () {
                /**
                 * Send request to API.
                 */
                let size = (this.extension == 'svg') ? 'svg' : 'lowres';
                if (this.file_type == 'image/gif') {
                    size = 'highres';
                }
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.id + '/preview/' + size;
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/0/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: (!this.$root.isLoggedIn() && this.in_share)
                        ? null : { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#' + this.prefix + 'asset-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#' + this.prefix + 'asset-title' + this.id)[0].scrollHeight > 20) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            viewAsset: function (tab = null) {
                if (!tab) {
                    if (this.isTemplate()) {
                        tab = this.$root.client.template_details_default_tab;
                        if ((tab == 'template-options') && !this.$root.hasPermission('templates_use')) {
                            tab = 'info';
                        }
                    } else {
                        tab = this.$root.client.asset_details_default_tab;
                        if ((tab == 'download') && !this.canDownload(this.in_share)) {
                            tab = 'info';
                        }
                    }
                }
                this.$parent.$parent.$parent.view_asset_from_selection = false;
                this.$parent.$parent.$parent.view_asset_tab = tab;
                this.$parent.$parent.$parent.view_asset = this.data;
                $('html, body').animate({ scrollTop: 0 }, 350);
            },
            /*showTemplateOptions: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'template-options');
                    if (component) {
                        this.showing_template_options = true;
                        component.reset(this.data, null, null, this);
                    }
                }
            },*/
            isTemplate: function () {
                if (!this.data) {
                    return false;
                }

                return this.data.template;
            },
            hasTemplateAdminPermission: function () {
                if (!this.data) {
                    return false;
                }
                if (!this.data.template) {
                    return false;
                }
                if (!this.$root.hasPermission('templates_view')) {
                    return false;
                }
                if (this.$root.hasPermission('templates_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_replace')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_manage_suspension')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_manage_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_delete')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_download_original')) {
                    return true;
                }

                return false;
            },
            canViewAsset: function () {
                if (this.in_share) {
                    if (this.isTemplate() && this.$root.isLoggedIn()) {
                        return this.$root.hasPermission('templates_view');
                    }

                    return true;
                }
                if (!this.isTemplate()) {
                    return this.$root.hasPermission('assets_view');
                }

                return this.$root.hasPermission('templates_view');
            },
            onThumbnailClick: function () {
                if (!this.isTemplate()) {
                    this.viewAsset();

                    return null;
                }
                if (this.hasTemplateAdminPermission()) {
                    this.viewAsset();

                    return null;
                }
                /*if (!this.$root.hasPermission('templates_use')) {
                    if (this.canViewAsset()) {
                        this.viewAsset();
                    }

                    return null;
                }
                if (!this.showing_template_options) {
                    this.showTemplateOptions();
                }*/
                if (this.canViewAsset()) {
                    this.viewAsset();
                }

                return null;
            },
            canDownload: function (inShare = false) {
                if (!inShare && !this.$root.hasPermission('assets_download')) {
                    return false;
                }
                if (inShare) {
                    if (this.$root.share_info.external_view_only && this.downloadOnlyAccess()) {
                        return false;
                    }
                }
                if (!this.data) {
                    return false;
                }
                if (this.data.suspended || this.isTemplate()) {
                    return false;
                }

                return (this.data.downloadable || this.data.web_image);
            },
            downloadOnlyAccess: function () {
                if (!this.$root.isLoggedIn() || !this.data) {
                    return true;
                }
                if (
                    this.data.template
                    && !this.$root.hasPermission('templates_view')
                    && !this.$root.hasPermission('templates_use')
                ) {
                    return true;
                }

                return this.data.download_only ? true : false;
            }
        }
    }
</script>
<style scoped>
    .asset-preview {
        /*border: 2px solid #e4e4e4;*/
        border: 2px solid #ffffff;
        border-radius: 0.35rem;
    }

    /*.asset-preview .thumbnail {
        padding: 0;
        height: 198px;
    }

    .asset-preview .thumbnail img {
        max-width: 100%;
    }

    .asset-preview.in-selection .thumbnail {
        height: 128px;
    }

    .asset-preview.in-selection .thumbnail img {
        max-height: 128px;
    }*/
</style>
