<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="select-item-modal-label">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <p v-if="subtitle">{{ subtitle }}</p>
            <div class="row">
                <div
                    v-if="(getItemTypes().length > 1)"
                    class="col-lg-3 col-md-4 col-5"
                    style="height: 500px; overflow-y: auto; border-right: 1px solid #eff2f7"
                >
                    <div
                        v-for="itemType in getItemTypes()"
                        :key="itemType.id"
                        :class="itemTypeClass(itemType.id)"
                        @click="changeItemType(itemType.id)"
                    >
                        <span class="mr-2" v-html="itemType.name"></span>
                        <span class="chevron-right ml-2">
                            <i class="far fa-chevron-right"></i>
                        </span>
                    </div>
                </div>
                <div class="col select-item-form-column">
                    <div
                        v-if="item_type == 'folder'"
                        :key="'select-folder-form'"
                        style="height: 500px; overflow-x: hidden; overflow-y: auto"
                    >
                        <div class="card">
                            <ul v-for="folder in folders" :key="folder.id" class="list-group list-group-flush">
                                <li
                                    :class="'list-group-item si-item'
                                        + ((selected_folder == folder.id) ? ' selected' : '')"
                                    @click="openFolder(folder)"
                                >
                                    <div class="font-size-18 contact-links float-right ml-2">
                                        <a
                                            v-if="(folder.subfolders.length > 0)"
                                            href="#"
                                            class="mr-3"
                                            v-b-tooltip.hover.left
                                            :title="isFolderOpen(folder) ? 'Hide ' + $root.contents.subfolders_title
                                                : 'View ' + $root.contents.subfolders_title"
                                            @click.prevent="doNothing()"
                                        >
                                            <i class="fal fa-folder-tree"></i>
                                        </a>
                                        <a
                                            v-if="(selected_folder != folder.id)"
                                            :key="'select-folder' + folder.id"
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="'Select ' + $root.contents.folder_title"
                                            @click.prevent="selectFolder(folder)"
                                        >
                                            <i class="fal fa-circle"></i>
                                        </a>
                                        <a
                                            v-else
                                            :key="'deselect-folder' + folder.id"
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="'Deselect ' + $root.contents.folder_title"
                                            @click.prevent="selectFolder(folder)"
                                        >
                                            <i class="fas fa-dot-circle"></i>
                                        </a>
                                    </div>
                                    <div
                                        v-if="!isFolderOpen(folder)"
                                        :key="'folder-closed' + folder.id"
                                        style="position: absolute; top: 15px"
                                    >
                                        <i class="fal fa-folder font-size-18"></i>
                                    </div>
                                    <div
                                        v-else
                                        :key="'folder-open' + folder.id"
                                        style="position: absolute; top: 15px"
                                    >
                                        <i class="fal fa-folder-open font-size-18"></i>
                                    </div>
                                    <div style="margin-left: 30px; padding-top: 3px">{{ folder.name }}</div>
                                </li>
                                <li
                                    v-for="subfolder in getSubfolders(folder)"
                                    :key="subfolder.id"
                                    :class="'list-group-item si-item'
                                        + ((selected_subfolder == subfolder.id) ? ' selected' : '')"
                                    :style="'display: ' + (isFolderOpen(folder) ? 'block' : 'none')"
                                >
                                    <div class="font-size-18 contact-links float-right ml-2">
                                        <a
                                            v-if="(selected_subfolder != subfolder.id)"
                                            :key="'select-subfolder' + subfolder.id"
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="'Select ' + $root.contents.subfolder_title"
                                            @click.prevent="selectSubfolder(subfolder)"
                                        >
                                            <i class="fal fa-circle"></i>
                                        </a>
                                        <a
                                            v-else
                                            :key="'deselect-subfolder' + subfolder.id"
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="'Deselect ' + $root.contents.subfolder_title"
                                            @click.prevent="selectSubfolder(subfolder)"
                                        >
                                            <i class="fas fa-dot-circle"></i>
                                        </a>
                                    </div>
                                    <div style="margin-left: 30px; padding-top: 3px">{{ subfolder.name }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="item_type == 'asset'" :key="'select-asset-form'">
                        <form v-on:submit.prevent="getAssets()">
                            <div class="row">
                                <div :class="assetFilterClass()">
                                    <div class="form-group">
                                        <searchable-option-list
                                            v-model="asset_folder"
                                            :value="asset_folder"
                                            :options="folders"
                                            field_id="select-asset-folder"
                                            :search_placeholder="'Select ' + $root.contents.folder_title"
                                            :readonly="assets_loading"
                                        ></searchable-option-list>
                                    </div>
                                </div>
                                <div
                                    v-if="(getSubfolders('asset-folder').length > 0)"
                                    :class="assetFilterClass()"
                                >
                                    <div class="form-group">
                                        <searchable-option-list
                                            v-model="asset_subfolder"
                                            :value="asset_subfolder"
                                            :options="getSubfolders('asset-folder')"
                                            field_id="select-asset-subfolder"
                                            :search_placeholder="'Select ' + $root.contents.subfolder_title"
                                            :readonly="assets_loading"
                                        ></searchable-option-list>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group keyword">
                                        <div class="input-group">
                                            <input
                                                v-model="asset_keyword"
                                                type="text"
                                                class="form-control search-by-keyword"
                                                placeholder="Keyword search"
                                                aria-label="Keyword search"
                                                :readonly="assets_loading"
                                            >
                                            <div class="input-group-append">
                                                <button
                                                    class="btn btn-secondary big-icon"
                                                    type="submit"
                                                    :disabled="assets_loading"
                                                >
                                                    <i class="fal fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <button
                                            v-if="asset_keyword"
                                            class="btn btn-link clear-keyword"
                                            @click.prevent="clearKeyword()"
                                        >
                                            <i class="far fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div
                            v-if="(assets_loaded && (assets_total_items == 0))"
                            class="alert alert-warning"
                            role="alert"
                        >
                            There are no {{ $root.contents.asset_title }}s that match your selection
                        </div>

                        <div class="asset-results">
                            <div v-if="(assets_total_items > 0) && !adding_all_assets" class="row">
                                <div
                                    v-for="item in assets"
                                    :key="item.id"
                                    :class="assetPreviewClass()"
                                >
                                    <select-item-asset-preview
                                        :data="item"
                                        :selected="(select_multiple ? (selected_asset.indexOf(item.id) > -1)
                                            : (item.id == selected_asset))"
                                        :select_multiple="select_multiple"
                                        :one_click_select="oneClickSelect()"
                                        v-on:change="onAssetSelected"
                                    ></select-item-asset-preview>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="assets_loading || adding_all_assets"
                            style="position: absolute; left: 0; bottom: 7px; width: 100%"
                        >
                            <loading></loading>
                        </div>
                    </div>
                    <div
                        v-if="item_type == 'latest_assets'"
                        :key="'select-latest_assets-form'"
                        style="height: 500px; overflow-x: hidden; overflow-y: auto"
                    >
                        <div class="form-group">
                            <label for="select-latest_assets">
                                Enter the number of latest uploads and edited
                                {{ $root.contents.asset_title }}s to show
                            </label>
                            <input
                                v-model="latest_assets"
                                type="number"
                                min="1"
                                max="100"
                                id="select-latest_assets"
                                class="form-control"
                            >
                            <small class="form-text text-muted">
                                Enter a number between 1 and 100
                            </small>
                        </div>
                    </div>
                    <div
                        v-if="item_type == 'keywords'"
                        :key="'select-keywords-form'"
                        style="height: 500px; overflow-x: hidden; overflow-y: auto"
                    >
                        <div class="form-group">
                            <keywords-field-with-assistant
                                v-model="keywords"
                                :value="keywords"
                                field_id="select-keywords"
                                placeholder="No keywords added"
                                keyword_placeholder="Add keywords manually - separate with commas"
                                :add_current_keyword="updating"
                                :keyword_categories="keyword_categories"
                                :no_header="true"
                            ></keywords-field-with-assistant>
                        </div>
                    </div>
                    <div
                        v-if="item_type == 'url'"
                        :key="'select-url-form'"
                        style="height: 500px; overflow-x: hidden; overflow-y: auto"
                    >
                        <div class="form-group">
                            <label for="select-url">Enter URL</label>
                            <input v-model="url" type="text" id="select-url" class="form-control">
                            <small class="form-text text-muted">
                                You should include the "http://" or "https://" parts at the start
                            </small>
                        </div>
                    </div>
                    <div
                        v-if="item_type == 'collection'"
                        :key="'select-collection-form'"
                        style="height: 500px; overflow-x: hidden; overflow-y: auto"
                    >
                        <div
                            v-if="(collections.length == 0)"
                            class="alert alert-warning"
                            role="alert"
                        >
                            You do not have any collections
                        </div>

                        <div v-else class="card">
                            <ul
                                v-for="collection in collections"
                                :key="collection.id"
                                class="list-group list-group-flush"
                            >
                                <li
                                    :class="'list-group-item si-item'
                                        + ((selected_collection == collection.id) ? ' selected' : '')"
                                >
                                    <div class="font-size-18 contact-links float-right ml-2">
                                        <a
                                            v-if="(selected_collection != collection.id)"
                                            :key="'select-collection' + collection.id"
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="Select collection"
                                            @click.prevent="selectCollection(collection)"
                                        >
                                            <i class="fal fa-circle"></i>
                                        </a>
                                        <a
                                            v-else
                                            :key="'deselect-collection' + collection.id"
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="Deselect collection"
                                            @click.prevent="selectCollection(collection)"
                                        >
                                            <i class="fas fa-dot-circle"></i>
                                        </a>
                                    </div>
                                    <div
                                        style="position: absolute; top: 15px"
                                    >
                                        <i class="fa-light fa-rectangle-history font-size-18"></i>
                                    </div>
                                    <div style="margin-left: 30px; padding-top: 3px">{{ collection.name }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!oneClickSelect()" class="modal-footer">
            <div v-if="((item_type == 'asset') && select_multiple)" style="position: absolute; left: 12px">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <button
                            v-if="!adding_all_assets"
                            :key="'add-all-assets'"
                            class="btn btn-secondary"
                            type="button"
                            :disabled="(assets_loading || (assets_total_items < 1))"
                            @click="addAllAssets()"
                        ><i class="far fa-plus mr-1"></i> Add all</button>
                        <button
                            v-else
                            :key="'adding-all-assets'"
                            class="btn btn-secondary"
                            type="button"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Adding</span>
                        </button>
                    </div>
                    <input
                        :value="assets_total_items"
                        type="text"
                        class="form-control text-center px-1"
                        :readonly="true"
                        style="max-width: 60px"
                    >
                </div>
            </div>
            <div
                v-if="((item_type == 'asset') && select_multiple)"
                style="position: absolute; left: 0; right: 0; margin: auto; width: 120px"
            >
                {{ selected_asset.length }} selected
            </div>

            <div v-if="item_type == 'folder'">
                <button
                    type="button"
                    class="btn btn-primary"
                    :key="'folder-select-button'"
                    :disabled="(!selected_folder && !selected_subfolder)"
                    @click="onSubmit()"
                >
                    <i class="far fa-check mr-1"></i> Done
                </button>
            </div>
            <div v-if="item_type == 'asset'">
                <button
                    type="button"
                    class="btn btn-primary"
                    :key="'asset-select-button'"
                    :disabled="((select_multiple && (selected_asset.length == 0))
                        || (!select_multiple && !selected_asset) || adding_all_assets)"
                    @click="onSubmit()"
                >
                    <i class="far fa-check mr-1"></i> Done
                </button>
            </div>
            <div v-if="item_type == 'latest_assets'">
                <button
                    type="button"
                    class="btn btn-primary"
                    :key="'latest_assets-select-button'"
                    :disabled="!latestAssetsNumberOk()"
                    @click="onSubmit()"
                >
                    <i class="far fa-check mr-1"></i> Done
                </button>
            </div>
            <div v-if="item_type == 'keywords'">
                <button
                    type="button"
                    class="btn btn-primary"
                    :key="'keywords-select-button'"
                    :disabled="!keywords"
                    @click="onSubmit()"
                >
                    <i class="far fa-check mr-1"></i> Done
                </button>
            </div>
            <div v-if="item_type == 'url'">
                <button
                    type="button"
                    class="btn btn-primary"
                    :key="'url-select-button'"
                    :disabled="!url"
                    @click="onSubmit()"
                >
                    <i class="far fa-check mr-1"></i> Done
                </button>
            </div>
            <div v-if="item_type == 'collection'">
                <button
                    type="button"
                    class="btn btn-primary"
                    :key="'collection-select-button'"
                    :disabled="!selected_collection"
                    @click="onSubmit()"
                >
                    <i class="far fa-check mr-1"></i> Done
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                item_types: ['asset'],
                item_type: 'asset',
                title: 'Select item',
                target_component: null,
                target_field: null,
                folders: [],
                asset_file_types: null,
                subtitle: null,
                url: null,
                folders_open: [],
                selected_folder: null,
                selected_subfolder: null,
                block_folder_open: false,
                asset_folder: null,
                asset_subfolder: null,
                asset_keyword: null,
                assets: [],
                assets_loading: false,
                assets_loaded: false,
                assets_current_page: 1,
                assets_total_items: 0,
                assets_last_page: 1,
                selected_asset: null,
                keywords: '',
                keyword_categories: [],
                selected_collection: null,
                collections: [],
                selected_collection: null,
                latest_assets: 10,
                select_multiple: false,
                adding_all_assets : false
            }
        },
        mounted () {
            var vm = this;
            $('.asset-results').on('scroll', function () {
                vm.handleScroll();
            });
        },
        watch: {
            block_folder_open: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.block_folder_open = false;
                    }, 200);
                }
            },
            asset_folder: function (val) {
                if (this.asset_subfolder) {
                    this.asset_subfolder = null;

                    return null;
                }
                this.getAssets();
            },
            asset_subfolder: function (val) {
                this.getAssets();
            }
        },
        methods: {
            reset: function (
                itemTypes,
                title,
                component,
                field,
                selectedItemType = null,
                selectedItem = null,
                fileTypes = null,
                subtitle = null,
                selectMultiple = false
            ) {
                this.errors = false;
                this.updating = false;
                this.item_types = itemTypes;
                this.title = title;
                this.target_component = component;
                this.target_field = field;
                this.asset_file_types = fileTypes;
                this.subtitle = subtitle;
                this.url = null;
                this.folders_open = [];
                this.selected_folder = null;
                this.selected_subfolder = null;
                this.asset_folder = null;
                this.asset_subfolder = null;
                this.asset_keyword = null;
                this.assets = null;
                this.assets_loading = false;
                this.assets_loaded = false;
                this.assets_current_page = 1;
                this.assets_total_items = 0;
                this.assets_last_page = 1;
                this.selected_asset = selectMultiple ? [] : null;
                this.keywords = '';
                this.selected_collection = null;
                this.latest_assets = 10;
                this.select_multiple = selectMultiple;
                this.adding_all_assets = false;

                $('.select-item-form-column').animate({ scrollTop: 0 }, 700);
                this.folders = [];
                if ((itemTypes.indexOf('folder') > -1) || (itemTypes.indexOf('asset') > -1)) {
                    this.getFolders(fileTypes);
                }
                this.keyword_categories = [];
                if (itemTypes.indexOf('keywords') > -1) {
                    this.getKeywordSuggestions();
                }
                this.collections = [];
                if (itemTypes.indexOf('collection') > -1) {
                    this.getCollections();
                }
                if (selectedItemType) {
                    if (selectedItemType == 'folder') {
                        this.selected_folder = selectedItem;
                    }
                    if (selectedItemType == 'subfolder') {
                        this.selected_subfolder = selectedItem;
                        selectedItemType = 'folder';
                    }
                    if (selectedItemType == 'asset') {
                        this.selected_asset = selectedItem;
                        if (!selectMultiple) {
                            this.asset_keyword = selectedItem;
                            
                            var vm = this;
                            setTimeout(function () {
                                vm.getAssets();
                            }, 200);
                        }
                    }
                    if (selectedItemType == 'collection') {
                        this.selected_collection = selectedItem;
                    }
                    this.item_type = selectedItemType;

                    return null;
                }
                this.item_type = itemTypes[0];
            },
            getItemTypes: function () {
                let items = [];
                if (this.item_types.indexOf('folder') > -1) {
                    items.push({
                        id: 'folder',
                        name: this.$root.ucfirst(this.$root.contents.folder_title) + ' or '
                            + this.$root.contents.subfolder_title
                    });
                }
                if (
                    (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_view'))
                    && (this.item_types.indexOf('asset') > -1)
                ) {
                    items.push({
                        id: 'asset',
                        name: this.$root.ucfirst(this.$root.contents.asset_title)
                    });
                }
                if (
                    (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_view'))
                    && (this.item_types.indexOf('latest_assets') > -1)
                ) {
                    items.push({
                        id: 'latest_assets',
                        name: 'Latest uploads and edited ' + this.$root.contents.asset_title + 's'
                    });
                }
                if (this.item_types.indexOf('keywords') > -1) {
                    items.push({
                        id: 'keywords',
                        name: 'Keywords'
                    });
                }
                if (this.item_types.indexOf('url') > -1) {
                    items.push({
                        id: 'url',
                        name: 'Website or other URL'
                    });
                }
                if (
                    (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_view'))
                    && (this.item_types.indexOf('collection') > -1)
                ) {
                    items.push({
                        id: 'collection',
                        name: 'Collection'
                    });
                }

                return items;
            },
            itemTypeClass: function (itemType) {
                if (itemType == this.item_type) {
                    return 'item-type active py-1 pl-2 pr-2';
                }

                return 'item-type py-1 pl-2 pr-2';
            },
            assetFilterClass: function () {
                if (this.$root.is_xl_screen) {
                    return 'col-4';
                }
                if (this.getItemTypes().length > 1) {
                    return 'col-lg-6 col-12';
                }

                return 'col-lg-4 col-sm-6 col-12';
            },
            assetPreviewClass: function () {
                if (this.$root.is_xl_screen) {
                    return 'col-3';
                }
                if (this.getItemTypes().length > 1) {
                    return 'col-lg-4 col-md-6 col-12';
                }

                return 'col-lg-3 col-md-4 col-sm-6 col-12';
            },
            changeItemType: function (itemType) {
                this.item_type = itemType;
                if ((itemType == 'url') && !this.url) {
                    setTimeout(function () {
                        $('#select-url').focus();
                    }, 200);
                }
            },
            onSubmit: function (delay = true) {
                this.updating = true;
                var vm = this;
                if (delay) {
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 200);

                    return null;
                }
                let type = this.item_type;
                let value = null;
                switch (this.item_type) {
                    case 'folder':
                        if (this.selected_subfolder) {
                            let folderId = null;
                            let folderName = null;
                            let subfolderName = null;
                            this.folders.forEach((folder) => {
                                folder.subfolders.forEach((subfolder) => {
                                    if (subfolder.id == this.selected_subfolder) {
                                        folderId = folder.id;
                                        folderName = folder.name;
                                        subfolderName = subfolder.name;
                                    }
                                });
                            });
                            type = 'subfolder';
                            value = folderId + '::' + folderName + '::' + this.selected_subfolder + '::' + subfolderName;
                        } else {
                            let folderName = null;
                            this.folders.forEach((folder) => {
                                if (folder.id == this.selected_folder) {
                                    folderName = folder.name;
                                }
                            });
                            type = 'folder';
                            value = this.selected_folder + '::' + folderName;
                        }
                        break;
                    case 'asset':
                        value = this.selected_asset;
                        break;
                    case 'latest_assets':
                        value = this.latest_assets;
                        break;
                    case 'keywords':
                        value = this.keywords;
                        break;
                    case 'url':
                        value = this.url;
                        break;
                    case 'collection':
                        let collectionName = null;
                        this.collections.forEach((collection) => {
                            if (collection.id == this.selected_collection) {
                                collectionName = collection.name;
                            }
                        });
                        value = this.selected_collection + '::' + collectionName;
                        break;
                }
                this.target_component.selectedItem(this.target_field, type, value);
                $('#select-item-modal').modal('hide');
                this.onClose();
            },
            onClose: function () {
                this.$parent.resetModalBackdrop();
                if (this.target_component.component_name == 'manage-images') {
                    setTimeout(function () {
                        $('#manage-images-modal').modal('show');
                    }, 500);
                }
            },
            getFolders: function (fileTypes = null) {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!pagination|!archived';
                if (this.$root.client.hide_empty_folders) {
                    url += '|!empty';
                }
                if (fileTypes) {
                    url += '|ft::' + btoa(this.asset_file_types.join(','));
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            getSubfolders: function (folder) {
                if (!folder) {
                    return [];
                }
                let tempFolder = null;
                if (folder == 'asset-folder') {
                    if (!this.asset_folder) {
                        return [];
                    }
                    let itemIndex = -1;
                    this.folders.forEach((item, index) => {
                        if (item.id == this.asset_folder) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex > -1) {
                        tempFolder = this.folders[itemIndex];
                    }
                } else {
                    tempFolder = folder;
                }
                if (!tempFolder) {
                    return [];
                }
                let items = [];
                tempFolder.subfolders.forEach((item) => {
                    if (!item.archived) {
                        items.push(item);
                    }
                });

                return items;
            },
            openFolder: function (folder) {
                if (this.block_folder_open || (folder.subfolders.length == 0)) {
                    return null;
                }
                const index = this.folders_open.indexOf(folder.id);
                if (index < 0) {
                    this.folders_open.push(folder.id);

                    return null;
                }
                this.folders_open.splice(index, 1);
            },
            isFolderOpen: function (folder) {
                return (this.folders_open.indexOf(folder.id) > -1);
            },
            selectFolder: function (folder) {
                this.block_folder_open = true;
                if (this.selected_folder == folder.id) {
                    this.selected_folder = null;

                    return null;
                }
                this.selected_folder = folder.id;
                this.selected_subfolder = null;
            },
            selectSubfolder: function (subfolder) {
                if (this.selected_subfolder == subfolder.id) {
                    this.selected_subfolder = null;

                    return null;
                }
                this.selected_folder = null;
                this.selected_subfolder = subfolder.id;
            },
            getKeywordSuggestions: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/keyword-suggestions';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.keyword_categories = [];
                    response.data.keyword_categories.forEach((item) => {
                        if (item.keyword_suggestions) {
                            if (item.keyword_suggestions.length > 0) {
                                vm.keyword_categories.push(item);
                            }
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            getCollections: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collections';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.collections = response.data.collections;
                })
                .catch(function (error) {
                    //
                });
            },
            selectCollection: function (collection) {
                if (this.selected_collection == collection.id) {
                    this.selected_collection = null;

                    return null;
                }
                this.selected_collection = collection.id;
            },
            doNothing: function () {
                return null;
            },
            clearKeyword: function () {
                if (this.assets_loading) {
                    return null;
                }
                this.asset_keyword = '';
                this.getAssets();
                setTimeout(function () {
                    $('.search-by-keyword').focus();
                }, 100);
            },
            getAssets: function (page = 1) {
                if (!this.asset_folder && !this.asset_keyword) {
                    this.assets = [];
                    this.assets_loaded = false;

                    return null;
                }
                if (
                    (this.assets_loading && !this.adding_all_assets)
                    || (page < 1)
                    || ((page > 1) && (page <= this.assets_current_page))
                ) {
                    return null;
                }
                this.assets_current_page = page;
                this.assets_loading = true;
                if (page == 1) {
                    this.assets = [];
                    this.assets_loaded = false;
                    this.assets_total_items = 0;
                    this.assets_last_page = 1;
                }
                let filters = ['!archived|!template'];
                if (this.asset_file_types) {
                    filters.push('ft::' + btoa(this.asset_file_types.join(',')));
                }
                if (this.asset_folder) {
                    filters.push('fr::' + this.asset_folder);
                }
                if (this.asset_subfolder) {
                    filters.push('sf::' + this.asset_subfolder);
                }
                if (this.asset_keyword) {
                    filters.push('kw::' + btoa(this.asset_keyword));
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/'
                    + filters.join('|') + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_total_items = response.data.resources.total;
                    vm.assets_current_page = response.data.resources.current_page;
                    vm.assets_last_page = response.data.resources.last_page;
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.assets.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.assets.push(item);
                        }
                    });
                    if (vm.adding_all_assets) {
                        vm.addAllAssets();

                        return null;
                    }
                    vm.assets_loaded = true;
                    vm.assets_loading = false;
                })
                .catch(function (error) {
                    vm.assets_loading = false;
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#select-item-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    //vm.assets_loading = false;
                });
            },
            onAssetSelected: function (event) {
                if (event.selected) {
                    if (this.select_multiple) {
                        if (this.selected_asset.indexOf(event.id) < 0) {
                            this.selected_asset.push(event.id);
                        }

                        return null;
                    }
                    this.selected_asset = event.id;
                    if (this.oneClickSelect()) {
                        this.onSubmit();
                    }

                    return null;
                }
                if (this.select_multiple) {
                    const index = this.selected_asset.indexOf(event.id);
                    if (index > -1) {
                        this.selected_asset.splice(index, 1);
                    }

                    return null;
                }
                this.selected_asset = null;
            },
            handleScroll() {
                if (this.item_type != 'asset') {
                    return null;
                }
                if ((this.assets_current_page < this.assets_last_page) && !this.assets_loading) {
                    const scrollTop = $('.asset-results').scrollTop();
                    const contentHeight = $('.asset-results > div').outerHeight();
                    const scrollPosition = contentHeight - (scrollTop + 450);
                    if (scrollPosition < 200) {
                        this.getAssets(this.assets_current_page + 1);
                    }
                }
            },
            latestAssetsNumberOk: function () {
                if (isNaN(this.latest_assets)) {
                    return false;
                }
                if ((this.latest_assets < 1) || (this.latest_assets > 100)) {
                    return false;
                }

                return true;
            },
            oneClickSelect: function () {
                if (this.item_types.length > 1) {
                    return false;
                }
                if (this.item_type != 'asset') {
                    return false;
                }

                return !this.select_multiple;
            },
            addAllAssets: function () {
                this.adding_all_assets = true;
                this.assets_loading = true;

                if (this.assets_current_page < this.assets_last_page) {
                    this.getAssets(this.assets_current_page + 1);

                    return null;
                }

                this.assets.forEach((item) => {
                    if (this.selected_asset.indexOf(item.id) < 0) {
                        this.selected_asset.push(item.id);
                    }
                });
                this.assets = [];
                this.onSubmit();
            }
        }
    }
</script>
<style scoped>
    .item-type {
        cursor: pointer;
        border-bottom: 1px solid #fff;
    }

    .item-type.active, .item-type:hover {
        background-color: #eff2f7;
    }

    .item-type .chevron-right {
        float: right;
        display: none;
    }

    .item-type.active .chevron-right {
        display: block;
        position: relative;
        top: 1px;
    }

    .si-item {
        border-bottom: 1px solid #eff2f7 !important;
    }

    .si-item:hover {
        background-color: #eff2f7;
    }

    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        bottom: 1px;
        right: 35px;
        z-index: 9;
    }

    .asset-results {
        height: 450px;
        overflow-x: hidden;
        overflow-y: auto;
    }
</style>
