<template>
    <div style="position: relative">
        <div v-if="tab">
            <ul class="nav nav-pills cl" role="tablist">
                <li class="nav-item">
                    <a
                        :class="'nav-link' + ((tab == 'p-comments') ? ' active' : '')"
                        id="p-comments-tab"
                        data-toggle="tab"
                        href="#p-comments"
                        role="tab"
                        aria-controls="p-comments"
                        :aria-selected="(tab == 'p-comments') ? 'true' : 'false'"
                        @click.prevent="onClickTab('p-comments')"
                    >Comments</a>
                </li>
                <li class="nav-item">
                    <a
                        :class="'nav-link' + ((tab == 'p-team') ? ' active' : '')"
                        id="p-team-tab"
                        data-toggle="tab"
                        href="#p-team"
                        role="tab"
                        aria-controls="p-team"
                        :aria-selected="(tab == 'p-team') ? 'true' : 'false'"
                        @click.prevent="onClickTab('p-team')"
                    >Team members</a>
                </li>
            </ul>
            <div class="contact-links font-size-22" style="position: absolute; top: 1px; right: 3px">
                <span v-if="$parent.canEditTeam()">
                    <a
                        href="#"
                        data-toggle="modal"
                        data-target="#proof-team-edit-modal"
                        @click.prevent="$parent.editTeam()"
                        v-b-tooltip.hover
                        title="Update team members"
                    ><i class="far fa-cog"></i></a>
                </span>
                <span class="d-md-none ml-2">
                    <a
                        v-if="!$parent.is_team_expanded"
                        href="#" @click.prevent="$parent.is_team_expanded = true"
                    >
                        <i class="far fa-chevron-circle-up"></i>
                    </a>
                    <span v-else>
                        <a href="#" @click.prevent="$parent.is_team_expanded = false">
                            <i class="far fa-chevron-circle-down"></i>
                        </a>
                    </span>
                </span>
            </div>
            <div class="tab-content pb-1">
                <div
                    :class="'tab-pane fade' + ((tab == 'p-comments') ? ' show active' : '')"
                    id="p-comments"
                    role="tabpanel"
                    aria-labelledby="p-comments-tab"
                >
                    <div v-if="comments_loading" class="p-3 pb-2">
                        <loading></loading>
                    </div>
                    <div v-else>
                        <div v-if="errors" v-html="errors" class="alert alert-danger ml-3 mt-3 mr-3 mb-0" role="alert">
                        </div>

                        <div v-if="!canComment()" class="pt-3 pl-3 pr-3">
                            <p v-if="proof.notes_html">
                                Message from proof leader: <span v-html="proof.notes_html"></span>
                            </p>
                        </div>

                        <form
                            v-on:submit.prevent="addComment()"
                            id="proof-team-comment-add"
                            class="pt-3 pl-3 pr-3 pb-2"
                            :style="'display: ' + (canComment() ? 'block' : 'none')"
                        >
                            <p v-if="proof.notes_html">
                                Message from proof leader: <span v-html="proof.notes_html"></span>
                            </p>
                            <div class="form-group mb-2">
                                <textarea
                                    v-model="comment"
                                    class="form-control mandatory-field"
                                    id="proof-comment"
                                    rows="3"
                                    v-on:focus="commentPlaceholder(true)"
                                    :placeholder="commentPlaceholder()"
                                    v-on:blur="commentPlaceholder(true)"
                                    @keydown="onKeydown($event)"
                                    @keyup="onKeyup($event)"
                                    :disabled="updating"
                                ></textarea>
                                <div class="dropdown">
                                    <div
                                        class="dropdown-menu comment-link"
                                        id="comment-link"
                                        style="max-width: 280px; max-height: 150px; overflow-x: hidden; overflow-y: auto;
                                            top: -20px"
                                    >
                                        <a
                                            v-for="option in comment_link_options"
                                            :key="option.id"
                                            :id="'comment-link-option' + option.id"
                                            href="#"
                                            :class="'dropdown-item' + ((option.id == selected_option) ? ' active' : '')"
                                            style="white-space: normal"
                                            @click.prevent="addLink(option)"
                                        >{{ option.name }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2 text-right">
                                <p v-if="!updating" class="float-left contact-links mb-0">
                                    <span
                                        class="ml-1 mr-2"
                                        v-b-tooltip.hover.bottom
                                        title="Mention a team member"
                                    >
                                        <a href="#" @click.prevent="focusCommentField('user')">
                                            <i class="far fa-at"></i>
                                        </a>
                                    </span>
                                </p>
                                <span v-if="!updating">
                                    <span>
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                            @click="addComment()"
                                            :disabled="!comment"
                                            style="width: 86px"
                                        >
                                            <i class="far fa-paper-plane mr-1"></i> Post
                                        </button>
                                    </span>
                                </span>
                                <span v-else>
                                    <button type="button" class="btn btn-primary">
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">Post</span>
                                    </button>
                                </span>
                            </div>
                        </form>

                        <div v-for="item in comments" :key="item.id" class="pl-3 pr-3">
                            <proof-comment
                                :item="item"
                                :comment_link_options="comment_link_options"
                                :selected_option="selected_option"
                                :team="team"
                                :readonly="!canComment()"
                                :user_profile_images="user_profile_images"
                            ></proof-comment>
                        </div>
                    </div>
                </div>
                <div
                    :class="'tab-pane fade' + ((tab == 'p-team') ? ' show active' : '')"
                    id="p-team"
                    role="tabpanel"
                    aria-labelledby="p-team-tab"
                >
                    <div class="p-3 pb-2">
                        <div
                            v-for="item in team"
                            :key="'user-' + item.user_id"
                            :class="'row collaborator' + ((reviewer_actions_open == item.user_id) ? ' active': '')"
                        >
                            <div v-if="proof.ordered" class="col-1 pt-3 pr-0" style="max-width: 20px">
                                <span v-if="item.reviewer">{{ item.review_order }}</span>
                            </div>
                            <div class="col-1 pt-3" style="min-width: 40px">
                                <span
                                    v-if="item.reviewer || item.leader"
                                    v-b-tooltip.hover.right
                                    :title="reviewStatusText(item)"
                                >
                                    <i :class="'fal ' + reviewStatusIcon(item) + ' fa-fw font-size-22'"></i>
                                </span>
                            </div>
                            <div class="col-1" style="min-width: 50px; padding-top: 11px">
                                <div v-if="item.profile_image" class="avatar-xs">
                                    <img class="rounded-circle avatar-xs" :src="item.profile_image" alt="avatar">
                                </div>
                                <div v-else class="avatar-xs">
                                    <span class="avatar-title rounded-circle">
                                        {{ $root.initial(item.first_name, item.last_name) }}
                                    </span>
                                </div>
                            </div>
                            <div class="col pt-2 pb-2">
                                <p class="mb-0">
                                    {{ item.first_name + ' ' + item.last_name }}
                                    <span
                                        v-if="item.leader"
                                        class="badge badge-primary font-size-11 ml-1"
                                        style="text-transform: uppercase"
                                    >
                                        Leader
                                    </span>
                                </p>
                                <p class="small text-muted mb-0" style="overflow-wrap: anywhere;">{{ item.email }}</p>
                            </div>
                            <div
                                v-if="$parent.isLeader() && !proof.suspended && !proof.archived && !proof.approved
                                    && item.reviewer && !item.leader"
                                class="col-1 font-size-18 contact-links pt-2"
                            >
                                <a
                                    href="#"
                                    class="float-right"
                                    @click.prevent="toggleReviewerActions(item.user_id)"
                                    v-b-tooltip.hover.left
                                    :title="((reviewer_actions_open == item.user_id) ? 'Hide' : 'Show') + ' actions'"
                                >
                                    <i class="far fa-ellipsis"></i>
                                </a>
                            </div>
                            <div
                                class="col-12 text-center"
                                :id="'reviewer-actions' + item.user_id"
                                style="display: none"
                            >
                                <!--<button
                                    v-if="!item.leader"
                                    :key="'email' + item.user_id"
                                    type="button"
                                    class="btn btn-secondary btn-sm mb-2"
                                    data-toggle="modal"
                                    data-target="#email-team-modal"
                                    @click.prevent="$parent.emailTeam(item)"
                                >Message</button>-->
                                <span v-if="proof.ordered">
                                    <button
                                        v-if="!skipping"
                                        :key="'skip' + item.user_id"
                                        type="button"
                                        class="btn btn-secondary btn-sm mb-2 ml-1"
                                        @click.prevent="skipReview(item)"
                                        :disabled="!canSkipReview(item)"
                                    >Skip</button>
                                    <button
                                        v-if="skipping"
                                        :key="'skipping' + item.user_id"
                                        type="button"
                                        class="btn btn-secondary btn-sm mb-2 ml-1"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">Skipping</span>
                                    </button>
                                </span>
                                <span v-if="!item.leader">
                                    <!--<button
                                        v-if="!removing"
                                        :key="'remove' + item.user_id"
                                        type="button"
                                        class="btn btn-secondary btn-sm mb-2 ml-1"
                                        @click.prevent="removeUser(item)"
                                    >Remove</button>
                                    <button
                                        v-if="removing"
                                        :key="'removing' + item.user_id"
                                        type="button"
                                        class="btn btn-secondary btn-sm mb-2 ml-1"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">Removing</span>
                                    </button>-->
                                    <button
                                        v-if="!chasing && !chased"
                                        :key="'chase' + item.user_id"
                                        type="button"
                                        class="btn btn-secondary btn-sm mb-2 ml-1"
                                        data-toggle="modal"
                                        data-target="#email-team-modal"
                                        @click.prevent="$parent.emailTeam(item, true)"
                                        :disabled="!canChaseReview(item)"
                                    >Chase</button>
                                    <button
                                        v-if="chasing"
                                        :key="'chasing' + item.user_id"
                                        type="button"
                                        class="btn btn-secondary btn-sm mb-2 ml-1"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">Chasing</span>
                                    </button>
                                    <button
                                        v-if="chased"
                                        :key="'chased' + item.user_id"
                                        type="button"
                                        class="btn btn-secondary btn-sm mb-2 ml-1"
                                    >
                                        <i class="far fa-check mr-1"></i> Chased
                                    </button>
                                    <p v-if="item.last_chased_at_formatted" class="small mt-2 mb-2">
                                        Last chased on {{ item.last_chased_at_formatted }}
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof', 'team', 'comments', 'user_profile_images', 'comments_loading'],
        data () {
            return {
                errors: false,
                tab: null,
                comment: '',
                comment_current_rows: {},
                updating: false,
                comment_link_options: [],
                comment_component: null,
                last_key: null,
                selected_option: null,
                reviewer_actions_open: null,
                chasing: false,
                chased: false,
                removing: false,
                skipping: false
            }
        },
        mounted () {
            if (this.$route.params.tab) {
                this.tab = 'p-' + this.$route.params.tab;
            } else {
                this.tab = 'p-comments';
            }
            $(document).on('click', function () {
                $('.comment-link').hide();
            });
            var vm = this;
            setTimeout(function () {
                $('.collaborate-bar .tab-content').on('scroll', function () {
                    vm.rePositionAddCommentForm();
                });
            }, 500);
            //
            jQuery.fn.trackRows = function () {
                return this.each(function () {
                    var createMirror = function (textarea) {
                        jQuery(textarea).after('<div class="autogrow-textarea-mirror"></div>');

                        return jQuery(textarea).next('.autogrow-textarea-mirror')[0];
                    }
                    var sendContentToMirror = function (textarea) {
                        mirror.innerHTML = String(textarea.value.substring(0, textarea.selectionStart))
                            .replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;')
                            .replace(/>/g, '&gt;').replace(/\n/g, '<br>') + '.<br>.';
                        calculateRowNumber();
                    }
                    var growTextarea = function () {
                        sendContentToMirror(this);
                    }
                    var calculateRowNumber = function () {
                        vm.comment_current_rows[textAreaId.replace(/-/g, '_')] = Math.round($(mirror).height() / 20) - 1;
                    }
                    // Create a mirror
                    var mirror = createMirror(this);
                    var textAreaId = this.id;
                    // Style the mirror
                    mirror.style.display = 'none';
                    mirror.style.wordWrap = 'break-word';
                    mirror.style.whiteSpace = 'normal';
                    mirror.style.padding = jQuery(this).css('padding');
                    mirror.style.width = jQuery(this).css('width');
                    mirror.style.fontFamily = jQuery(this).css('font-family');
                    mirror.style.fontSize = jQuery(this).css('font-size');
                    mirror.style.lineHeight = jQuery(this).css('line-height');
                    // Bind the textarea's event
                    this.onkeyup = growTextarea;
                    this.onclick = growTextarea;
                });
            };
        },
        watch: {
            tab: function (val) {
                this.errors = false;
                this.rePositionAddCommentForm();
            },
            comments_loading: function (val) {
                if (!val) {
                    setTimeout(function () {
                        $('#proof-comment').trackRows();
                    }, 500);
                }
            },
            reviewer_actions_open: function (val, oldVal) {
                if (oldVal) {
                    setTimeout(function () {
                        $('#reviewer-actions' + oldVal).slideUp(350);
                    }, 200);
                }
                if (val) {
                    setTimeout(function () {
                        $('#reviewer-actions' + val).slideDown(350);
                    }, 200);
                }
            },
            chased: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.chased = false;
                    }, 2000);
                }
            }
        },
        methods: {
            toggleReviewerActions: function (userId) {
                if (this.reviewer_actions_open == userId) {
                    this.reviewer_actions_open = null;

                    return null;
                }
                this.reviewer_actions_open = userId;
            },
            canComment: function () {
                if (!this.proof) {
                    return false;
                }
                if (this.proof.archived) {
                    return false;
                }

                return this.$parent.isTeamMember();
            },
            rePositionAddCommentForm: function () {
                if (!this.canComment()) {
                    return null;
                }
                let scrollTop = 0;
                if ((this.tab == 'p-comments') && $('.collaborate-bar .tab-content')[0]) {
                    scrollTop = $('.collaborate-bar .tab-content')[0].scrollTop;
                }
                $('#proof-team-comment-add').css('top', scrollTop);
            },
            onClickTab: function (tab) {
                this.tab = tab;
                if (!this.$root.is_md_screen) {
                    this.$parent.is_team_expanded = true;
                }
            },
            reviewStatusText: function (item) {
                if (!item.reviewer && item.leader) {
                    return 'Leader';
                }
                if (item.review_status == 'DL') {
                    return 'Downloaded';
                }
                if (item.review_status == 'NC') {
                    return 'Approved with no changes';
                }
                if (item.review_status == 'WC') {
                    return 'Returned with changes';
                }
                if (item.review_status == 'MC') {
                    return 'Approved - minor changes';
                }
                if (item.review_status == 'AP') {
                    return 'Proof approved final';
                }
                if (item.review_status == 'SK') {
                    return 'Skipped by the leader';
                }

                return 'Not reviewed';
            },
            reviewStatusIcon: function (item) {
                if (!item.reviewer && item.leader) {
                    return 'fa-crown gold';
                }
                if (item.review_status == 'DL') {
                    return 'fa-message-pen';
                }
                if (item.review_status == 'NC') {
                    return 'fa-file-check text-success';
                }
                if (item.review_status == 'WC') {
                    return 'fa-file-lines text-danger';
                }
                if (item.review_status == 'MC') {
                    return 'fa-memo-circle-check gold';
                }
                if (item.review_status == 'AP') {
                    return 'fa-thumbs-up';
                }
                if (item.review_status == 'SK') {
                    return 'fa-message-xmark';
                }

                return 'fa-message';
            },
            commentPlaceholder: function (update = false) {
                const focused = $('#proof-comment').is(':focus');
                if (focused) {
                    if (update) {
                        $('#proof-comment').attr('placeholder', null);
                    }

                    return null;
                }
                const text = 'Make a comment';
                if (update) {
                    $('#proof-comment').attr('placeholder', text);

                    return null;
                }

                return text;
            },
            addComment: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API.
                 */
                const data = {
                    comment: this.comment,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof-comment/'
                    + this.proof.id;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.message) {
                        vm.$parent.getComments();
                        vm.comment = '';
                        $('.collaborate-bar .tab-content')[0].scrollTop = 0;
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#proof-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            onKeydown: function (event) {
                this.last_key = event.key;
                if ((event.key != 'ArrowUp') && (event.key != 'ArrowDown') && (event.key != 'Enter')) {
                    return null;
                }
                if (this.lookup(event, true)) {
                    event.preventDefault();
                }
            },
            onKeyup: function (event) {
                this.lookup(event);
            },
            lookup: function (event, test = false) {
                if (event.key == '@') {
                    return this.lookupUser(null, null, false, test);
                }
                let position = $('#proof-comment')[0].selectionStart;
                if (position > 0) {
                    let word = '';
                    let char = '';
                    do {
                        position--;
                        char = this.comment.substr(position, 1).toString();
                        if ((char != '@') && (char != ' ')) {
                            word = char + word;
                        }
                    } while ((position > 0) && (char != '@') && (char != ' '));

                    if (char == '@') {
                        return this.lookupUser(null, word.toLowerCase(), false, test);
                    }
                }
                $('.comment-link').hide();

                return false;
            },
            focusCommentField: function (lookup = null) {
                $('#proof-comment').focus();
                if (!lookup) {
                    return null;
                }
                const symbol = (lookup == 'user') ? '@' : '#';
                const position = $('#proof-comment')[0].selectionStart || this.comment.length;
                const text1 = this.comment.substr(0, position);
                const text2 = this.comment.substr(position);
                this.comment = text1 + symbol + text2;
                var vm = this;
                var focusPosition = position + 1;
                if (lookup == 'user') {
                    setTimeout(function () {
                        $('#proof-comment')[0].selectionEnd = focusPosition;
                        vm.lookupUser();
                    }, 200);
                }
            },
            lookupUser: function (component = null, keyword = null, isReply = false, test = false) {
                if (this.team.length < 2) {
                    return false;
                }
                this.comment_component = component;
                this.comment_link_options = [];
                this.team.forEach((item) => {
                    if (item.user_id != this.$root.user.id) {
                        if (
                            !keyword
                            || (item.first_name.toLowerCase().indexOf(keyword) > -1)
                            || (item.last_name.toLowerCase().indexOf(keyword) > -1)
                            || (item.email.toLowerCase().indexOf(keyword) > -1)
                        ) {
                            this.comment_link_options.push({
                                id: item.user_id,
                                name: item.first_name + ' ' + item.last_name,
                                link: item.first_name + ' ' + item.last_name,
                                symbol: '@'
                            });
                        }
                    }
                });

                if (this.comment_link_options.length == 0) {
                    if (!test) {
                        if (this.comment_component) {
                            if (isReply) {
                                $('#reply-comment-link-' + this.comment_component.item.id).hide();
                            } else {
                                $('#comment-link-' + this.comment_component.item.id).hide();
                            }
                        } else {
                            $('.comment-link').hide();
                        }
                        this.selected_option = null;
                        this.last_key = null;
                    }

                    return false;
                }
                if (test) {
                    return true;
                }
                var vm = this;
                setTimeout(function () {
                    vm.rePositionCommentLink(isReply);
                }, 100);
            },
            rePositionCommentLink: function (isReply) {
                let position = 0;
                let scrollTop = 0;
                if (this.comment_component) {
                    let commentId = null;
                    let currentRows = 0;
                    if (isReply) {
                        commentId = '#proof-reply-comment-' + this.comment_component.item.id;
                        currentRows =
                            this.comment_current_rows['proof_reply_comment_' + this.comment_component.item.id] || 1;
                        position = Math.round($(commentId).innerHeight() / 20) - currentRows;
                        scrollTop = $('#proof-reply-comment-' + this.comment_component.item.id)[0].scrollTop;
                        $('#reply-comment-link-' + this.comment_component.item.id).css(
                            'top',
                            '-' + (position * 20 - 13 + scrollTop) + 'px'
                        );
                        $('#reply-comment-link-' + this.comment_component.item.id).show();
                    } else {
                        commentId = '#proof-comment-' + this.comment_component.item.id;
                        currentRows = this.comment_current_rows['proof_comment_' + this.comment_component.item.id] || 1;
                        position = Math.round($(commentId).innerHeight() / 20) - currentRows;
                        scrollTop = $('#proof-comment-' + this.comment_component.item.id)[0].scrollTop;
                        $('#comment-link-' + this.comment_component.item.id).css(
                            'top',
                            '-' + (position * 20 - 13 + scrollTop) + 'px'
                        );
                        $('#comment-link-' + this.comment_component.item.id).show();
                    }
                    this.scrollToComment(this.comment_component.item.id);
                } else {
                    position = Math.round($('#proof-comment').innerHeight() / 20)
                        - (this.comment_current_rows.proof_comment || 1);
                    scrollTop = $('#proof-comment')[0].scrollTop;
                    $('#comment-link').css('top', '-' + (position * 20 - 13 + scrollTop) + 'px');
                    $('#comment-link').show();
                }
                ////
                let currentIndex = -1;
                let prevIndex = -1;
                let nextIndex = -1;
                if (this.selected_option) {
                    this.comment_link_options.forEach((option, index) => {
                        if (option.id == this.selected_option) {
                            currentIndex = index;
                        } else {
                            if (currentIndex == -1) {
                                prevIndex = index;
                            }
                            if ((currentIndex > -1) && (nextIndex == -1)) {
                                nextIndex = index;
                            }
                        }
                    });
                    if (currentIndex == -1) {
                        this.selected_option = null;
                    }
                }
                if (!this.selected_option) {
                    this.selected_option = this.comment_link_options[0].id;
                } else {
                    if ((this.last_key == 'ArrowDown') && (nextIndex > -1)) {
                        this.selected_option = this.comment_link_options[nextIndex].id;
                        this.scrollToCommentLinkOption(isReply);
                    }
                    if ((this.last_key == 'ArrowUp') && (prevIndex > -1)) {
                        this.selected_option = this.comment_link_options[prevIndex].id;
                        this.scrollToCommentLinkOption(isReply);
                    }
                    if ((this.last_key == 'Enter') && (currentIndex > -1)) {
                        this.selected_option = null;
                        this.last_key = null;
                        this.addLink(this.comment_link_options[currentIndex]);
                        if (this.comment_component) {
                            if (isReply) {
                                $('#reply-comment-link-' + this.comment_component.item.id).hide();
                            } else {
                                $('#comment-link-' + this.comment_component.item.id).hide();
                            }
                        } else {
                            $('.comment-link').hide();
                        }
                        this.selected_option = null;
                        this.last_key = null;
                    }
                }
            },
            scrollToCommentLinkOption: function (isReply) {
                let scrollTo = 0;
                let found = false;
                this.comment_link_options.forEach((option) => {
                    if (!found) {
                        if (option.id != this.selected_option) {
                            if (this.comment_component) {
                                if (isReply) {
                                    scrollTo += $(
                                        '#reply-comment-link-' + this.comment_component.item.id + '-option' + option.id
                                    ).outerHeight();
                                } else {
                                    scrollTo += $(
                                        '#comment-link-' + this.comment_component.item.id + '-option' + option.id
                                    ).outerHeight();
                                }
                            } else {
                                scrollTo += $('#comment-link-option' + option.id).outerHeight();
                            }
                        } else {
                            found = true;
                        }
                    }
                });
                if (this.comment_component) {
                    if (isReply) {
                        $('#reply-comment-link-' + this.comment_component.item.id).animate({ scrollTop: scrollTo }, 350);
                    } else {
                        $('#comment-link-' + this.comment_component.item.id).animate({ scrollTop: scrollTo }, 350);
                    }
                } else {
                    $('#comment-link').animate({ scrollTop: scrollTo }, 200);
                }
            },
            addLink: function (option) {
                let position = 0;
                let text1 = '';
                let text2 = '';
                if (this.comment_component) {
                    if (this.comment_component.replying) {
                        position = $('#proof-reply-comment-' + this.comment_component.item.id)[0].selectionStart
                            || this.comment_component.reply_comment.length;
                        text1 = this.comment_component.reply_comment.substr(0, position);
                        text2 = this.comment_component.reply_comment.substr(position);
                    } else {
                        position = $('#proof-comment-' + this.comment_component.item.id)[0].selectionStart
                            || this.comment_component.comment.length;
                        text1 = this.comment_component.comment.substr(0, position);
                        text2 = this.comment_component.comment.substr(position);
                    }
                } else {
                    position = ($('#proof-comment')[0].selectionStart || this.comment.length);
                    text1 = this.comment.substr(0, position);
                    text2 = this.comment.substr(position);
                }

                if (text1.length == 0) {
                    text1 = '[' + option.symbol;
                } else {
                    let word = '';
                    let char = '';
                    do {
                        position--;
                        char = text1.substr(position, 1).toString();
                        if (char != ' ') {
                            word = char + word;
                        }
                    } while ((position > 0) && (char != option.symbol) && (char != ' '));

                    if (char == option.symbol) {
                        text1 = text1.substr(0, (text1.length - word.length));
                    }
                    if (
                        (text1.length > 0)
                        && (text1.substr(text1.length - 1, 1) != ' ')
                        && (text1.substr(text1.length - 1, 1) != '[')
                        && (text1.substr(text1.length - 1, 1) != '\n')
                    ) {
                        text1 += ' ';
                    }
                    if (text1.substr(text1.length - 1, 1) != '[') {
                        text1 += '[';
                    }
                    text1 += option.symbol;
                }
                
                if ((text2.length == 0) || (text2.substr(0, 1) != ' ')) {
                    text2 = ' ' + text2;
                }
                text2 = ']' + text2;

                if (this.comment_component) {
                    if (this.comment_component.replying) {
                        this.comment_component.reply_comment = text1 + option.link + text2;
                        $('#proof-reply-comment-' + this.comment_component.item.id).focus();

                        return null;
                    }
                    this.comment_component.comment = text1 + option.link + text2;
                    $('#proof-comment-' + this.comment_component.item.id).focus();

                    return null;
                }
                this.comment = text1 + option.link + text2;
                $('#proof-comment').focus();
            },
            scrollToComment: function (id, delay = true) {
                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.scrollToComment(id, false);
                    }, 100);

                    return null;
                }
                let scrollTop = $('#proof-team-comment-add').outerHeight() || 0;
                let found = false;
                let repliesScrollTop = 0;
                let replyFound = false;
                this.comments.forEach((item) => {
                    if (item.id == id) {
                        found = true;
                    }
                    if (item.replies.length > 0) {
                        repliesScrollTop = 0;
                        replyFound = false;
                        item.replies.forEach((reply) => {
                            if (reply.id == id) {
                                replyFound = true;
                            }
                            if (!replyFound) {
                                repliesScrollTop += $('#proof-team-comment-' + reply.id).outerHeight();
                            }
                        });
                        if (replyFound) {
                            found = true;
                            scrollTop += $('#proof-original-comment-' + item.id).outerHeight();
                            scrollTop += $('#proof-reply-comment-add-' + item.id).outerHeight() || 0;
                            scrollTop += repliesScrollTop;
                        }
                    }
                    if (!found) {
                        scrollTop += $('#proof-team-comment-' + item.id).outerHeight();
                    }
                });
                $('.collaborate-bar .tab-content').animate({ scrollTop: scrollTop }, 700);
            },
            canChaseReview: function (item) {
                if (this.proof.in_use && this.proof.current_reviewer) {
                    if (this.proof.current_reviewer.user_id == item.user_id) {
                        return true;
                    }
                }
                if (this.proof.ordered) {
                    if (!this.proof.in_use && this.proof.next_reviewer) {
                        if (this.proof.next_reviewer.user_id == item.user_id) {
                            return true;
                        }
                    }

                    return false;
                }
                if (this.proof.in_use) {
                    return false;
                }

                return ((item.review_status == 'UR') || (item.review_status == 'DL'));
            },
            chaseReview: function (item) {
                this.chasing = true;

                /**
                 * Send chase proof request to API.
                 */
                const data = {
                    user_id: item.user_id,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/chase';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.chased = true;
                    vm.$parent.getProof(vm.proof.id);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            vm.$root.doLogout();

                            return null;
                        }
                        const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        appComponent.message = {
                            type: 'error',
                            title: 'Chase',
                            text: error.response.data.error || 'Something went wrong, please try again'
                        };
                        $('#message-modal').modal('show');
                    }
                })
                .finally(() =>
                    vm.chasing = false
                );
            },
            removeUser: function (user, confirmed = false) {
                if (confirmed) {
                    const component = this.$root.findComponent(this.$parent, 'proof-team-edit');
                    if (component) {
                        this.removing = true;
                        component.reset();
                        component.removeProofUser(user);
                        component.onSubmit();
                        var vm = this;
                        setTimeout(function () {
                            vm.removing = false;
                        }, 1000);
                    }

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Remove team member',
                    text: 'Are you sure you want to remove: ' + user.first_name + ' ' + user.last_name + '?',
                    component: this,
                    action: 'remove-' + user.user_id
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action.indexOf('remove-') > -1) {
                    const userId = action.substring(7);
                    this.removeUser(userId, true);
                }
            },
            canSkipReview: function (item) {
                return ((item.review_status == 'UR') || (item.review_status == 'DL'));
            },
            skipReview: function (user) {
                const component = this.$root.findComponent(this.$parent, 'proof-skip-review');
                if (component) {
                    component.reset(user);
                    $('#skip-review-modal').modal('show');
                }
            }
        }
    }
</script>
<style scoped>
    #proof-team-comment-add {
        position: relative;
        background-color: #fff;
        z-index: 2;
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    }

    #proof-team-comment-add.static {
        position: static;
    }

    .gold {
        color: #bf9000;
    }
</style>
