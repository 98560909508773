import { render, staticRenderFns } from "./RelatedAssetPreview.vue?vue&type=template&id=5c2024d8&scoped=true"
import script from "./RelatedAssetPreview.vue?vue&type=script&lang=js"
export * from "./RelatedAssetPreview.vue?vue&type=script&lang=js"
import style0 from "./RelatedAssetPreview.vue?vue&type=style&index=0&id=5c2024d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2024d8",
  null
  
)

export default component.exports