<template>
    <div>
        <div
            :class="'assets-container'
                + ((!isArchive() && ($root.current_collection || ($root.selected_assets.length > 0))) ? ' padding' : '')"
        >
            <div class="assets-grid">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="(!loading && (total_items == 0))" class="alert alert-warning" role="alert">
                    There are no results for your search
                </div>

                <div v-if="(total_items > 0)">
                    <div v-if="$root.is_4xl_screen" class="d-none d-xl-block">
                        <div v-for="group in grouped_items_4xl" :key="group.id" class="row">
                            <div v-for="item in group.items" :key="item.id" class="col" style="width: 14.2857%">
                                <asset-preview
                                    v-if="(item.id > 0)"
                                    :data="item"
                                    :selected="($root.selected_assets.indexOf(item.id) > -1)"
                                    :collections="collections"
                                    :collection="$root.current_collection"
                                    :is_visible="!isFullScreenPanel()"
                                ></asset-preview>
                            </div>
                        </div>
                    </div>

                    <div v-if="($root.is_xl_screen && !$root.is_3xl_screen)" class="d-none d-xl-block">
                        <div v-for="group in grouped_items" :key="group.id" class="row">
                            <div v-for="item in group.items" :key="item.id" class="col" style="width: 20%">
                                <asset-preview
                                    v-if="(item.id > 0)"
                                    :data="item"
                                    :selected="($root.selected_assets.indexOf(item.id) > -1)"
                                    :collections="collections"
                                    :collection="$root.current_collection"
                                    :is_visible="!isFullScreenPanel()"
                                ></asset-preview>
                            </div>
                        </div>
                    </div>

                    <div v-if="(!$root.is_xl_screen || ($root.is_3xl_screen && !$root.is_4xl_screen))" class="row">
                        <div
                            v-for="item in items"
                            :key="item.id"
                            :class="($root.is_xxl_screen ? 'col-xl-2 ' : '') + 'col-lg-3 col-md-4 col-sm-6'"
                        >
                            <asset-preview
                                :data="item"
                                :selected="($root.selected_assets.indexOf(item.id) > -1)"
                                :collections="collections"
                                :collection="$root.current_collection"
                                :is_visible="!isFullScreenPanel()"
                            ></asset-preview>
                        </div>
                    </div>
                </div>
            </div>

            <collection-linked-file-details></collection-linked-file-details>
            <asset-details></asset-details>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <asset-filters
            :asset_filters="$root.asset_filters"
            :asset_result_types="asset_result_types"
            :asset_result_orientations="asset_result_orientations"
            :asset_result_uploaders="asset_result_uploaders"
            :asset_result_user_groups="asset_result_user_groups"
            :asset_result_custom_fields="asset_result_custom_fields"
            :folder="folder"
            :assets_info="$root.assets_info"
        ></asset-filters>

        <asset-selection
            v-if="(!isArchive() && !$root.editing_artwork)"
            :selected="$root.selected_assets"
            :collections="collections"
            :collection="$root.current_collection"
        ></asset-selection>

        <div
            v-if="$route.name == 'assets'"
            class="modal fade"
            id="filter-share-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="filter-share-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <filter-share></filter-share>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'assets',
                loading: false,
                errors: false,
                grouped_items: [],
                grouped_items_4xl: [],
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                asset_types: [],
                asset_result_types: null,
                asset_result_orientations: null,
                asset_result_uploaders: null,
                asset_result_user_groups: null,
                asset_result_custom_fields: null,
                folder: 0,
                view_asset: false,
                view_asset_tab: this.$root.client.asset_details_default_tab,
                view_asset_from_selection: false,
                container_height: 0,
                scroll_top: 0,
                collections: [],
                auto_open_asset_details: false,
                custom_fields: [],
                counts_loading: false,
                select_all_page: 0,
                select_all_asset_ids: []
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');

            if (!this.$root.hasPermission('assets_access') && !this.$root.hasPermission('templates_access')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }

            if (this.$route.params.filters) {
                // If filtered by query reset filters
                this.$root.temp_filters = [];
                const params = this.$route.params.filters.split('|');
                let redirect = false;
                let requestedAssetIds = [];
                let fromApprovalEmail = false;
                params.forEach((item) => {
                    if (item.substr(0, 4) == 'id::') {
                        requestedAssetIds = item.substr(4).split(',');
                        redirect = true;
                    }
                    if (item == 'approval') {
                        fromApprovalEmail = true;
                    }
                });
                if (redirect) {
                    if (fromApprovalEmail) {
                        requestedAssetIds.forEach((item) => {
                            const assetId = parseInt(item);
                            if (this.$root.assets_from_approval_email.indexOf(assetId) < 0) {
                                this.$root.assets_from_approval_email.push(assetId);
                            }
                        });
                    }
                    requestedAssetIds.forEach((assetId) => {
                        this.$root.addFilter('temp_filters', {
                            key: 'resource_id',
                            value: parseInt(assetId),
                            text: '&quot;' + assetId + '&quot;',
                            filters_key: 'asset_filters'
                        });
                    });
                    this.$router.push({ name: 'assets' });

                    return null;
                }
            }
            this.getAssets();
            this.getCollections();
            console.log('get assets: component mounted');
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            view_asset: function (val, oldVal) {
                if (val) {
                    if (oldVal) {
                        if ((val.id < 0) && (oldVal.id > 0) || (val.id > 0) && (oldVal.id < 0)) {
                            (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
                            var vm = this;
                            setTimeout(function () {
                                (vm.view_asset.id < 0) ? vm.showLinkedFileDetails(vm.view_asset)
                                    : vm.showAssetDetails(vm.view_asset);
                            }, 800);

                            return null;
                        }
                        (val.id < 0) ? this.showLinkedFileDetails(val, false) : this.showAssetDetails(val, false);

                        return null;
                    }
                    (val.id < 0) ? this.showLinkedFileDetails(val) : this.showAssetDetails(val);

                    return null;
                }
                if (!oldVal) {
                    return null;
                }
                (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
            },
            total_items: function (val) {
                this.$root.status_info.right = this.total_items + ' ' + this.$root.contents.asset_title;
                if (this.total_items !== 1) {
                    this.$root.status_info.right += 's';
                }
            }
        },
        methods: {
            showLinkedFileDetails: function (linkedFile, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'collection-linked-file-details');
                component.setLinkedFile(linkedFile);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.assets-container').outerHeight();
                $('.assets-container').css('min-height', this.container_height);
                $('.assets-grid').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.linked-file-details').fadeIn(350, function () {
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            hideLinkedFileDetails: function () {
                var vm = this;
                $('.linked-file-details').fadeOut(350, function () {
                    $('.assets-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.assets-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'collection-linked-file-details');
                        component.setLinkedFile(null);
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            showAssetDetails: function (asset, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'asset-details');
                component.setAsset(asset, this.view_asset_tab);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.assets-container').outerHeight();
                $('.assets-container').css('min-height', this.container_height);
                $('.assets-grid').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.asset-details').fadeIn(350, function () {
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            hideAssetDetails: function () {
                var vm = this;
                $('.asset-details').fadeOut(350, function () {
                    $('.assets-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.assets-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'asset-details');
                        component.setAsset(null);
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            getAssets: async function (page = 1, closeAssetDetails = true, getResultCounts = false) {
                if (
                    this.isArchive()
                    && !this.$root.hasPermission('assets_archive')
                    && !this.$root.hasPermission('templatess_archive')
                ) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                if (
                    !getResultCounts
                    && (
                        this.loading
                        || (page < 1)
                        || ((page > 1) && (page <= this.current_page))
                    )
                ) {
                    return false;
                }
                if (closeAssetDetails) {
                    this.view_asset = false;
                }
                if (!getResultCounts) {
                    this.current_page = page;
                    this.loading = true;
                    this.errors = false;
                    if (page == 1) {
                        this.select_all_asset_ids = [];
                        await this.getAssetTypes();
                        await this.getCustomFields();
                        this.grouped_items = [];
                        this.grouped_items_4xl = [];
                        this.items = [];
                        this.total_items = 0;
                        this.current_page = 1;
                        this.last_page = 1;
                        this.folder = 0;
                        this.asset_result_types = null;
                        this.asset_result_custom_fields = null;
                        this.asset_result_orientations = null;
                        this.asset_result_uploaders = null;
                        this.asset_result_user_groups = null;
                    }
                    this.auto_open_asset_details = false;
                }

                let filters = [this.isArchive() ? 'archived' : '!archived'];
                if (getResultCounts) {
                    filters.push(getResultCounts);
                }
                let assetIds = [];
                let sortBy = null;
                let hasKeywords = false;
                let limit = null;
                let reverseImageSearch = false;
                this.$root.asset_filters.forEach((item) => {
                    if (item.key == 'main-keyword') {
                        let values = item.value.split('||');
                        values.forEach((keyword) => {
                            filters.push('mk::' + btoa(keyword));
                        });
                        hasKeywords = true;
                    }
                    if (item.key == 'keyword') {
                        filters.push('ak::' + btoa(item.value));
                        hasKeywords = true;
                    }
                    if ((item.key == 'folder') && (item.value > 0)) {
                        filters.push('fr::' + item.value);
                        this.folder = item.value;
                    }
                    if (item.key == 'subfolder') {
                        filters.push('sf::' + item.value);
                    }
                    if ((item.key == 'asset_type') && !item.auto) {
                        filters.push('rt::' + item.value);
                    }
                    if (item.key == 'orientation') {
                        filters.push('or::' + item.value);
                    }
                    if (item.key == 'date_range') {
                        filters.push('dr::' + item.value);
                    }
                    if (item.key == 'uploader') {
                        filters.push('cb::' + item.value);
                    }
                    if ((item.key.substr(0, 2) == 'cf') && !item.auto) {
                        filters.push(item.key + '::' + btoa(item.value));
                    }
                    if (item.key == 'user_group') {
                        filters.push('ug::' + item.value);
                    }
                    if (item.key == 'resource_id') {
                        assetIds.push(item.value);
                    }
                    if ((item.key == 'restricted') || (item.key == '!restricted')) {
                        filters.push(item.key);
                    }
                    if (item.key == 'limit') {
                        limit = item.value;
                    }
                    if (item.key == 'sort_by') {
                        sortBy = item.value;
                    }
                    if (item.key == 'template') {
                        filters.push(item.value ? 'template' : '!template');
                    }
                    if (item.key == 'reverse-image-search') {
                        assetIds = item.value.split(',');
                        reverseImageSearch = true;
                    }
                });
                if (reverseImageSearch) {
                    limit = assetIds.length;
                    sortBy = 'relevance';
                }
                if (limit) {
                    filters.push('lt::' + limit);
                }
                if ((sortBy == 'relevance') && !hasKeywords && !reverseImageSearch) {
                    sortBy = null;
                    this.$root.addFilter('asset_filters', {
                        key: 'sort_by',
                        value: null
                    });
                }
                if (sortBy) {
                    filters.push('sb::' + sortBy);
                }
                if (assetIds.length > 0) {
                    filters.push('in::' + assetIds.join(','));
                    if (assetIds.length == 1) {
                        this.auto_open_asset_details = true;
                    }
                }
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources' + filtersString;
                if (!getResultCounts) {
                    url += '?page=' + page;
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if ('resource_types' in response.data) {
                        vm.setAssetTypeFilters(response.data.resource_types);
                        if (!vm.loading) {
                            vm.getAssets(1, false, 'counts-cf');
                        } else {
                            vm.counts_loading = false;
                        }

                        return null;
                    }
                    if ('custom_fields' in response.data) {
                        vm.setCustomFieldFilters(response.data.custom_fields);
                        if (!vm.loading) {
                            vm.getAssets(1, false, 'counts-rest');
                        } else {
                            vm.counts_loading = false;
                        }

                        return null;
                    }
                    if ('orientations' in response.data) {
                        vm.asset_result_orientations = response.data.orientations;
                        vm.asset_result_uploaders = response.data.uploaders;
                        vm.setUserGroupFilters(response.data.user_groups);
                        vm.counts_loading = false;

                        return null;
                    }
                    vm.total_items = response.data.resources.total;
                    vm.current_page = response.data.resources.current_page;
                    vm.last_page = response.data.resources.last_page;
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.items.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.items.push(item);
                        }
                    });

                    if (response.data.resources.total > 1) {
                        vm.getSelectAllAssetIds();
                    }

                    if (response.data.resources.current_page == 1) {
                        vm.counts_loading = true;
                        vm.getAssets(1, false, 'counts-rt');
                    } else if (!vm.counts_loading) {
                        if (vm.asset_result_custom_fields == null) {
                            vm.counts_loading = true;
                            vm.getAssets(1, false, 'counts-cf');
                        } else if (vm.asset_result_orientations == null) {
                            vm.counts_loading = true;
                            vm.getAssets(1, false, 'counts-rest');
                        }
                    }

                    if ((vm.items.length == 1) && vm.auto_open_asset_details) {
                        // Do not open template details unless user has access.
                        if (!vm.items[0].template || vm.$root.hasPermission('templates_view')) {
                            vm.view_asset_from_selection = false;
                            vm.view_asset_tab = vm.$root.client.asset_details_default_tab;
                            vm.view_asset = vm.items[0];
                        }
                    }

                    let group_items = [];
                    vm.grouped_items = [];
                    vm.grouped_items_4xl = [];
                    vm.items.forEach((item, index) => {
                        group_items.push(item);
                        if (group_items.length == 5) {
                            vm.grouped_items.push({ id: 'g' + index, items: group_items });
                            group_items = [];
                        }
                    });
                    if (group_items.length > 0) {
                        for (let n = group_items.length; n < 5; n++) {
                            group_items.push({ id: (0 - n)});
                        }
                        vm.grouped_items.push({ id: 'last', items: group_items });
                    }
                    // 4xl
                    group_items = [];
                    vm.items.forEach((item, index) => {
                        group_items.push(item);
                        if (group_items.length == 7) {
                            vm.grouped_items_4xl.push({ id: 'g' + index, items: group_items });
                            group_items = [];
                        }
                    });
                    if (group_items.length > 0) {
                        for (let n = group_items.length; n < 7; n++) {
                            group_items.push({ id: (0 - n)});
                        }
                        vm.grouped_items_4xl.push({ id: 'last', items: group_items });
                    }
                    vm.loading = false;
                })
                .catch(function (error) {
                    vm.loading = false;
                    vm.counts_loading = false;
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            setAssetTypeFilters: function (assetTypes) {
                this.asset_result_types = assetTypes;
                if (!this.$root.client.asset_type_filter_menu) {
                    return null;
                }
                let filters = this.$root.asset_filters;
                let manualFilters = [];
                for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                    if (filters[filterIndex].key == 'asset_type') {
                        if (!filters[filterIndex].auto) {
                            manualFilters.push('asset_type' + filters[filterIndex].value);
                        }
                        filters.splice(filterIndex, 1);
                    }
                }
                this.asset_types.forEach((item) => {
                    if (assetTypes[item.id]) {
                        if (assetTypes[item.id].filtered > 0) {
                            this.$root.addFilter('asset_filters', {
                                key: 'asset_type',
                                value: item.id,
                                text: item.name.replace(/"/g, '&quot;').replace(/'/g, '&apos;')
                                    + ' <span class="badge badge-primary font-size-11">'
                                    + assetTypes[item.id].filtered + '</span>',
                                filters_key: 'asset_filters',
                                auto: (manualFilters.indexOf('asset_type' + item.id) < 0)
                            });
                        }
                    }
                });
            },
            setCustomFieldFilters: function (customFields) {
                this.asset_result_custom_fields = customFields;
                let filters = [];
                this.$root.asset_filters.forEach((item) => {
                    filters.push(item);
                });
                let manualFilters = [];
                for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                    if (filters[filterIndex].key.substr(0, 2) == 'cf') {
                        if (!filters[filterIndex].auto) {
                            manualFilters.push(filters[filterIndex].key + filters[filterIndex].value);
                        }
                        let customFieldId = parseInt(filters[filterIndex].key.substr(2));
                        this.custom_fields.forEach((item) => {
                            if ((item.id == customFieldId) && item.filter_menu) {
                                filters.splice(filterIndex, 1);
                            }
                        });
                    }
                }
                this.$root.temp_filters = filters;
                this.custom_fields.forEach((item) => {
                    if (item.filter_menu && (item.id in customFields) && ('options' in item)) {
                        if (item.options !== null) {
                            item.options.forEach((option) => {
                                if (option.value in customFields[item.id]) {
                                    if (customFields[item.id][option.value].filtered > 0) {
                                        this.$root.addFilter('temp_filters', {
                                            key: 'cf' + item.id,
                                            value: option.value,
                                            text: option.text.replace(/"/g, '&quot;').replace(/'/g, '&apos;')
                                                + ' <span class="badge badge-primary font-size-11">'
                                                + customFields[item.id][option.value].filtered + '</span>',
                                            filters_key: 'asset_filters',
                                            filter_menu: item.filter_menu,
                                            auto: (manualFilters.indexOf('cf' + item.id + option.value) < 0)
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
                filters = [];
                this.$root.temp_filters.forEach((item) => {
                    filters.push(item);
                });
                this.$root.asset_filters = filters;
                this.$root.temp_filters = [];
            },
            setUserGroupFilters: function (userGroups) {
                this.asset_result_user_groups = userGroups;
                let filters = this.$root.asset_filters;
                for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                    if (filters[filterIndex].key == 'user_group') {
                        let userGroupId = filters[filterIndex].value;
                        if (!(userGroupId in userGroups)) {
                            filters.splice(filterIndex, 1);
                        } else if (!(userGroups[userGroupId].filtered > 0)) {
                            filters.splice(filterIndex, 1);
                        }
                    }
                }
            },
            getAssetTypes: async function () {
                var vm = this;
                if (vm.asset_types.length > 0) {
                    return null;
                }

                try {
                    const url = vm.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/resource-types/!pagination';
                    const response = await axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    });

                    vm.asset_types = response.data.resource_types;
                } catch (error) {
                    //
                }

                return null;
            },
            getCustomFields: async function () {
                var vm = this;
                if (vm.custom_fields.length > 0) {
                    return null;
                }

                try {
                    const url = vm.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                    const response = await axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    });

                    vm.custom_fields = [];
                    response.data.custom_fields.forEach((item) => {
                        if ((item.type == 'singleselect') || (item.type == 'multiselect')) {
                            vm.custom_fields.push(item);
                        }
                    });
                } catch (error) {
                    //
                }

                return null;
            },
            showAssetFilters: function () {
                setTimeout(function () {
                    $('body').addClass('right-bar-enabled');
                }, 100);
            },
            handleScroll(event) {
                if (this.view_asset || this.$root.editing_artwork) {
                    return null;
                }
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getAssets(this.current_page + 1, false);
                        console.log('get assets: scroll autoload');
                    }
                }
            },
            getCollections: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collections';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.collections = response.data.collections;
                    // Delete chosen collection if no longer exists.
                    if (vm.$root.current_collection) {
                        let found = false;
                        vm.collections.forEach((item, index) => {
                            if (item.id == vm.$root.current_collection) {
                                found = true;
                            }
                        });
                        if (!found) {
                            vm.$root.current_collection = null;
                            vm.$root.selected_assets = [];
                        }
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            isArchive: function () {
                if (!this.$route.params.filters) {
                    return false;
                }
                let archivedAssets = false;
                const params = this.$route.params.filters.split('|');
                params.forEach((item) => {
                    if (item == 'archived') {
                        archivedAssets = true;
                    }
                });

                return archivedAssets;
            },
            isFullScreenPanel: function () {
                if (this.$root.adding_asset || this.$root.adding_template || this.$root.editing_artwork) {
                    return true;
                }

                return false;
            },
            getSelectAllAssetIds: function () {
                if (this.current_page > this.select_all_page) {
                    this.items.forEach((item) => {
                        if (this.select_all_asset_ids.indexOf(item.id) < 0) {
                            this.select_all_asset_ids.unshift(item.id);
                        }
                    });
                }

                if (this.select_all_page < this.current_page) {
                    this.select_all_page = this.current_page;
                }

                var vm = this;
                if (
                    !this.$root.current_collection
                    && (this.select_all_page < this.last_page)
                    && (
                        (this.total_items <= 1000)
                        || (this.$root.selected_assets.length > this.select_all_asset_ids.length)
                    )
                ) {
                    this.select_all_page++;
                    let filters = [this.isArchive() ? 'archived' : '!archived'];
                    let assetIds = [];
                    let sortBy = null;
                    let limit = null;
                    let reverseImageSearch = false;
                    this.$root.asset_filters.forEach((item) => {
                        if (item.key == 'main-keyword') {
                            let values = item.value.split('||');
                            values.forEach((keyword) => {
                                filters.push('mk::' + btoa(keyword));
                            });
                        }
                        if (item.key == 'keyword') {
                            filters.push('ak::' + btoa(item.value));
                        }
                        if ((item.key == 'folder') && (item.value > 0)) {
                            filters.push('fr::' + item.value);
                        }
                        if (item.key == 'subfolder') {
                            filters.push('sf::' + item.value);
                        }
                        if ((item.key == 'asset_type') && !item.auto) {
                            filters.push('rt::' + item.value);
                        }
                        if (item.key == 'orientation') {
                            filters.push('or::' + item.value);
                        }
                        if (item.key == 'date_range') {
                            filters.push('dr::' + item.value);
                        }
                        if (item.key == 'uploader') {
                            filters.push('cb::' + item.value);
                        }
                        if ((item.key.substr(0, 2) == 'cf') && !item.auto) {
                            filters.push(item.key + '::' + btoa(item.value));
                        }
                        if (item.key == 'user_group') {
                            filters.push('ug::' + item.value);
                        }
                        if (item.key == 'resource_id') {
                            assetIds.push(item.value);
                        }
                        if ((item.key == 'restricted') || (item.key == '!restricted')) {
                            filters.push(item.key);
                        }
                        if (item.key == 'limit') {
                            limit = item.value;
                        }
                        if (item.key == 'sort_by') {
                            sortBy = item.value;
                        }
                        if (item.key == 'template') {
                            filters.push(item.value ? 'template' : '!template');
                        }
                        if (item.key == 'reverse-image-search') {
                            assetIds = item.value.split(',');
                            reverseImageSearch = true;
                        }
                    });
                    if (reverseImageSearch) {
                        limit = assetIds.length;
                        sortBy = 'relevance';
                    }
                    if (limit) {
                        filters.push('lt::' + limit);
                    }
                    if (sortBy) {
                        filters.push('sb::' + sortBy);
                    }
                    if (assetIds.length > 0) {
                        filters.push('in::' + assetIds.join(','));
                    }
                    const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                    /**
                     * Send request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources' + filtersString
                        + '?page=' + this.select_all_page;

                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.select_all_page = response.data.resources.current_page;
                        response.data.resources.data.forEach((item) => {
                            if (vm.select_all_asset_ids.indexOf(item.id) < 0) {
                                vm.select_all_asset_ids.unshift(item.id);
                            }
                        });
                        vm.getSelectAllAssetIds();
                    })
                    .catch(function (error) {
                        //
                    });

                    return null;
                }
                this.select_all_page = 0;
            },
            selectAll: function (deselectAll = false) {
                if (deselectAll) {
                    this.select_all_asset_ids.forEach((assetId) => {
                        let index = this.$root.selected_assets.indexOf(assetId);
                        if (index > -1) {
                            this.$root.selected_assets.splice(index, 1);
                        }
                    });

                    return null;
                }
                let assetIds = this.$root.selected_assets;
                this.select_all_asset_ids.forEach((assetId) => {
                    if (this.$root.selected_assets.indexOf(assetId) < 0) {
                        assetIds.push(assetId);
                    }
                });
                this.$root.selected_assets = assetIds;
            }
        }
    }
</script>
<style scoped>
    .assets-container.padding {
        padding-bottom: 12rem;
    }

    @media (max-width: 767px) {
        .assets-container.padding {
            padding-bottom: 14rem;
        }
    }
</style>
