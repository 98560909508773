<template>
    <div>
        <form v-on:submit.prevent="onSubmit()">
            <h5 class="mb-3 mt-2 mt-sm-0">
                Edit {{ custom_field.name }}
                <span class="small text-muted">({{ field_type_name }})</span>
            </h5>

            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label
                            :for="'name' + custom_field.id"
                            :id="'name-label' + custom_field.id"
                            class="mandatory-label"
                        >
                            Title *
                        </label>
                        <input
                            v-model="custom_field.name"
                            type="text"
                            :id="'name' + custom_field.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('name', custom_field.id)"
                            v-on:change="$root.unHighlightErrors('name', custom_field.id)"
                        >
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="custom_field.required"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'required' + custom_field.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'required' + custom_field.id">
                                Required
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="custom_field.filter"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'filter' + custom_field.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'filter' + custom_field.id">
                                Show in filter sidebar
                            </label>
                        </div>
                    </div>
                    <div v-if="((data.type == 'singleselect') || (data.type == 'multiselect'))" class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="custom_field.filter_menu"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'filter_menu' + custom_field.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'filter_menu' + custom_field.id">
                                Show as menu bar
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="custom_field.asset_info"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'asset_info' + custom_field.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'asset_info' + custom_field.id">
                                Show on {{ $root.contents.asset_title }} record
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="custom_field.related_assets"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'related_assets' + custom_field.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'related_assets' + custom_field.id">
                                Related {{ $root.contents.asset_title }}s field
                            </label>
                        </div>
                    </div>
                    <!--<div class="form-group">
                        <label
                            :for="'help_text' + custom_field.id"
                            :id="'help_text-label' + custom_field.id"
                        >
                            Help text
                        </label>
                        <input
                            v-model="custom_field.help_text"
                            type="text"
                            :id="'help_text' + custom_field.id"
                            class="form-control"
                            :readonly="updating"
                        >
                    </div>-->
                </div>
                <div class="col">
                    <div class="form-group">
                        <label
                            :for="'folders' + custom_field.id"
                            :id="'folders-label' + custom_field.id"
                        >
                            Assigned to {{ $root.contents.folders_title }}
                        </label>
                        <searchable-option-list
                            v-model="custom_field.folders"
                            :value="(custom_field.folders || [])"
                            :multiple="true"
                            :options="getFolderOptions()"
                            :field_id="'folders' + custom_field.id"
                            :field_class="'form-control pb-2'"
                            :readonly="updating"
                            :select_all="true"
                        ></searchable-option-list>
                    </div>
                    <div
                        v-if="($root.isFeatured('assets') && $root.isFeatured('templates'))"
                        class="form-group"
                        :key="'area-' + counter"
                    >
                        <label
                            :for="'area' + custom_field.id"
                            :id="'area-label' + custom_field.id"
                        >
                            Available for
                        </label>
                        <searchable-option-list
                            v-model="custom_field.area"
                            :value="custom_field.area"
                            :options="getAreaOptions()"
                            :field_id="'area' + custom_field.id"
                            :field_class="'form-control pb-2'"
                            :readonly="updating"
                            v-on:change="checkArea()"
                        ></searchable-option-list>
                    </div>
                </div>
            </div>

            <div
                v-if="((custom_field.type == 'multiselect') || (custom_field.type == 'singleselect'))"
                class="form-group mt-4"
            >
                <label>Drop down options</label>
                <draggable
                    v-model="custom_field_options"
                    ghost-class="vue-drag-ghost"
                    tag="div"
                    handle=".drag-handle"
                    animation="200"
                >
                    <div v-for="option in custom_field_options" :key="option.id" class="row mb-1">
                        <div class="col-lg-1 col-2 pr-0 pt-1 font-size-18 contact-links">
                            <a
                                href="#"
                                class="drag-handle"
                                style="cursor: move"
                                v-b-tooltip.hover
                                title="Re-order"
                                @click.prevent="doNothing()"
                            >
                                <i class="fas fa-arrow-down-arrow-up"></i>
                            </a>
                        </div>
                        <div class="col-lg-10 col-8 pl-0 pr-0">
                            <input
                                v-model="option.text"
                                type="text"
                                :id="'option-text' + custom_field.id + '_' + option.id"
                                class="form-control"
                                :readonly="updating"
                            >
                        </div>
                        <div class="col-lg-1 col-2">
                            <button
                                v-if="custom_field_options.length > 1"
                                type="button"
                                class="btn btn-secondary big-icon"
                                :disabled="updating"
                                @click="deleteOption(option.id)"
                                v-b-tooltip.hover.left
                                title="Delete option"
                                style="width: 100%; min-width: 40px"
                            >
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </draggable>

                <div class="row mb-1">
                    <div class="col-lg-1 col-sm-2 col-3 pr-0"></div>
                    <div class="col-lg-11 col-sm-10 col-9 pl-0">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            :disabled="updating"
                            @click="addOption()"
                        >
                            <i class="far fa-plus mr-1"></i> Add option
                        </button>
                    </div>
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="col">
                    <span v-if="!updating">
                        <button
                            type="button"
                            class="btn btn-primary mr-2"
                            :disabled="updating"
                            @click="onSubmit()"
                        >
                            <i class="far fa-save mr-1"></i> Save
                        </button>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Save</span>
                        </button>
                    </span>

                    <button
                        type="button"
                        class="btn btn-secondary mr-2"
                        :disabled="updating"
                        @click="$parent.$parent.deleteCustomField(custom_field)"
                    >
                        <i class="far fa-trash-alt mr-1"></i> Delete
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        props: ['data', 'field_types', 'update', 'cancel', 'folders'],
        components: {
            draggable,
        },
        data () {
            return {
                errors: false,
                updating: false,
                custom_field: {},
                custom_field_options: [],
                original_data: {},
                field_type_name: '',
                counter: 0
            }
        },
        mounted () {
            if (this.data) {
                this.original_data = this.data;
                this.updateOriginalData();
            }
        },
        watch: {
            data: function (val) {
                this.original_data = val;
                this.updateOriginalData();
            },
            update: function (val) {
                if (val == this.custom_field.id) {
                    this.onSubmit();
                }
            },
            cancel: function (val) {
                if (val == this.custom_field.id) {
                    /*for (const [key, value] of Object.entries(this.original_data)) {
                        if (key != 'edit_mode') {
                            this.custom_field[key] = value;
                        }
                    }*/
                    this.original_data.edit_mode = false;
                    this.$parent.$parent.custom_fields.push({ id: -1 });
                    this.$parent.$parent.custom_fields.pop();
                    this.$parent.$parent.cancel_item = 0;
                }
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                this.$parent.$parent.update_item = this.custom_field.id;
                this.custom_field.options = null;
                if ((this.custom_field.type == 'multiselect') || (this.custom_field.type == 'singleselect')) {
                    this.custom_field.options = [];
                    this.custom_field_options.forEach((item) => {
                        if (item.text) {
                            let option = {
                                value: item.value || item.text,
                                text: item.text
                            };
                            this.custom_field.options.push(option);
                        }
                    });
                }
                let selectedFolders = [];
                if (this.custom_field.folders) {
                    this.custom_field.folders.forEach((item) => {
                        if (item > 0) {
                            selectedFolders.push(item);
                        }
                    });
                }
                /**
                 * Send update custom field request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-field/'
                    + this.custom_field.id;
                const data = {
                    name: this.custom_field.name,
                    type: this.custom_field.type,
                    required: (this.custom_field.required || false),
                    help_text: (this.custom_field.help_text || null),
                    folders: selectedFolders,
                    options: this.custom_field.options,
                    filter: (this.custom_field.filter === false) ? false : true,
                    asset_info: (this.custom_field.asset_info === false) ? false : true,
                    filter_menu: (this.custom_field.filter_menu || false),
                    area: this.custom_field.area,
                    related_assets: (this.custom_field.related_assets === false) ? false : true
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    for (const [key, value] of Object.entries(this.custom_field)) {
                        if (key != 'edit_mode') {
                            this.original_data[key] = value;
                        }
                    }
                    vm.original_data.edit_mode = false;
                    vm.$parent.$parent.custom_fields.push({ id: -1 });
                    vm.$parent.$parent.custom_fields.pop();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = 'Something went wrong, please try again';
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                if (key == 'name') {
                                    vm.errors += 'The title field is required.';
                                } else {
                                    vm.errors += value.join('<br>') + '<br>';
                                }
                                $('#' + key + '-label' + vm.custom_field.id).addClass('text-danger');
                                $('#' + key + vm.custom_field.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                    vm.$parent.$parent.update_item = 0;
                });
            },
            updateOriginalData: function () {
                this.updating = true;
                this.field_types.forEach((item) => {
                    if (item.id == this.original_data.type) {
                        this.field_type_name = item.name.toLowerCase();
                    }
                });
                for (const [key, value] of Object.entries(this.original_data)) {
                    this.custom_field[key] = value;
                }
                if ((this.custom_field.type == 'multiselect') || (this.custom_field.type == 'singleselect')) {
                    if (!('options' in this.custom_field)) {
                        this.custom_field_options = [{
                            id: 0,
                            value: '',
                            text: ''
                        }];
                    } else if (this.custom_field.options == null) {
                        this.custom_field_options = [{
                            id: 0,
                            value: '',
                            text: ''
                        }];
                    } else if (this.custom_field.options.length == 0) {
                        this.custom_field_options = [{
                            id: 0,
                            value: '',
                            text: ''
                        }];
                    } else {
                        this.custom_field_options = [];
                        this.original_data.options.forEach((item, index) => {
                            item.id = index;
                            this.custom_field_options.push(item);
                        });
                    }
                }
                if (!this.custom_field.folders) {
                    this.custom_field.folders = [-1];
                } else if (this.custom_field.folders.length == 0) {
                    this.custom_field.folders = [-1];
                }
                this.updating = false;
            },
            getFolderOptions: function () {
                let options = [{
                    id: -1,
                    name: 'All ' + this.$root.contents.folders_title
                }];
                if (this.folders) {
                    this.folders.forEach((item) => {
                        let name = item.name;
                        if (item.archived) {
                            name += ' (archived)';
                        }
                        options.push({
                            id: item.id,
                            name: name
                        });
                    });
                }

                return options;
            },
            getAreaOptions: function () {
                const assetsOn = this.$root.isFeatured('assets');
                const templatesOn = this.$root.isFeatured('templates');

                let options = [];
                if ((assetsOn && templatesOn) || (!assetsOn && !templatesOn) || (this.custom_field.area == 'B')) {
                    options.push({
                        id: 'B',
                        name: 'Both ' + this.$root.contents.asset_title + 's and ' + this.$root.contents.template_title
                            + 's',
                        disabled: (!assetsOn || !templatesOn)
                    });
                }
                if (assetsOn || (this.custom_field.area == 'A')) {
                    options.push({
                        id: 'A',
                        name: this.$root.ucfirst(this.$root.contents.asset_title) + 's only',
                        disabled: !assetsOn
                    });
                }
                if (templatesOn || (this.custom_field.area == 'T')) {
                    options.push({
                        id: 'T',
                        name: this.$root.ucfirst(this.$root.contents.template_title) + 's only',
                        disabled: !templatesOn
                    });
                }

                return options;
            },
            checkArea: function () {
                if (!this.custom_field.area) {
                    var vm = this;
                    setTimeout(function () {
                        const areas = vm.getAreaOptions();
                        vm.custom_field.area = areas[0].id;
                        vm.counter++;
                    }, 200);
                }
            },
            addOption: function () {
                var newId = 0;
                this.custom_field_options.forEach((item) => {
                    if (item.id > newId) {
                        newId = item.id;
                    }
                });
                newId++;
                const item = {
                    id: newId,
                    value: '',
                    text: ''
                };
                this.custom_field_options.push(item);

                var vm = this;
                setTimeout(function () {
                    $('#option-text' + vm.custom_field.id + '_' + newId).focus();
                }, 200);
            },
            deleteOption: function (itemId) {
                let itemIndex = -1;
                this.custom_field_options.forEach((item, index) => {
                    if (item.id == itemId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.custom_field_options.splice(itemIndex, 1);
                }
            },
            doNothing: function () {
                return null;
            }
        }
    }
</script>
<style scoped>
    .custom-control {
        z-index: 0;
    }
</style>
