<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="flyte-form-modal-label">Flyte file sender</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" :disabled="sending">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent">
                <div v-if="sent" class="alert alert-success" role="alert">
                    <div class="row">
                        <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                        <div class="col" v-html="sent"></div>
                    </div>
                </div>
                You can check what you have sent on your
                <router-link :to="{ name: 'my-account' }" data-dismiss="modal">My account</router-link>
                page.
            </div>
            <div class="row" :style="'display: ' + (!sent ? 'flex' : 'none')">
                <div class="col-md-6 col-12" style="max-height: 450px; overflow-y: auto">
                    <vue-dropzone
                        ref="flyteDropzone"
                        id="flyte-dropzone"
                        :options="dropzone_options"
                        v-on:vdropzone-sending="onSendingFile"
                        v-on:vdropzone-upload-progress="onProgressUpdate"
                        v-on:vdropzone-success="onFileUploaded"
                        v-on:vdropzone-error="onUploadError"
                        v-on:vdropzone-drag-over="onDragOver"
                        v-on:vdropzone-drag-leave="onDragLeave"
                        v-on:vdropzone-drop="onDragLeave"
                        v-on:vdropzone-file-added="onFileAdded"
                        :useCustomSlot=true
                    >
                        <div v-for="file in uploaded_files" :key="file.id" class="dz-uploaded-file p-2 mb-1">
                            <div class="dz-thumbnail">
                                <span v-if="!file.preview_image">
                                    <img
                                        :src="$root.imagePlaceholder(file.filename)"
                                        class="img-fluid"
                                        alt=""
                                    >
                                </span>
                                <img
                                    v-else
                                    :src="file.preview_image"
                                    class="img-fluid"
                                    alt=""
                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                >
                                <div
                                    v-if="file.preview_image"
                                    class="view-image-icon"
                                    :id="'view-image-icon' + file.id"
                                    v-b-tooltip.hover
                                    title="Enlarge"
                                    @click="viewImage(file)"
                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                >
                                    <i class="fal fa-expand-arrows"></i>
                                </div>
                            </div>
                            <div class="dz-filename">{{ file.filename }}</div>
                            <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                            <div class="dz-remove">
                                <a
                                    v-if="!sending"
                                    href="#"
                                    v-b-tooltip.hover
                                    title="Delete"
                                    @click.prevent="removeUploadedFile(file.id)"
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </a>
                                <span v-if="sending"><i class="fal fa-trash-alt"></i></span>
                            </div>
                            <div
                                class="dz-upload-info"
                                :id="'upload-info' + file.id"
                                style="left: 160px"
                            >
                                <i class="fal fa-clock mr-1"></i> Pending upload
                            </div>
                            <div
                                class="dz-progress"
                                :id="'upload-progress' + file.id"
                                style="width: calc(100% - 172px)"
                            >
                                <div class="dz-upload"></div>
                            </div>
                        </div>
                        <a
                            href="#"
                            class="dz-add-files"
                            id="flyte-add-files"
                            @click.prevent="doNothing()"
                            :style="dzAddFilesStyle()"
                        >
                            <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                            Click or drop files here to upload
                        </a>
                    </vue-dropzone>
                </div>
                <div class="col-md-6 col-12 mt-md-0 mt-3">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <form v-if="$root.hasPermission('flyte_access')" v-on:submit.prevent="onSubmit()">
                        <div class="form-group dropdown">
                            <label id="flyte-recipients-label" class="mandatory-label">
                                Recipient email(s) *
                            </label>
                            <div
                                class="form-control mandatory-field pb-0"
                                id="flyte-recipients"
                                @click="focusRecipientKeyword()"
                                style="height: auto; min-height: 38px"
                            >
                                <span
                                    v-for="item in recipients"
                                    :key="item.email"
                                    class="badge badge-soft-secondary contact-links font-size-12 mr-2 mb-2"
                                    v-b-tooltip.hover
                                    title=""
                                >
                                    <span v-if="!item.name">{{ item.email }}</span>
                                    <span v-else v-b-tooltip.hover :title="item.email">{{ item.name }}</span>
                                    <a
                                        v-if="!sending"
                                        href="#"
                                        v-b-tooltip.hover
                                        title="Remove"
                                        @click.prevent="removeRecipient(item)"
                                    >
                                        <i class="far fa-times"></i>
                                    </a>
                                    <span v-if="sending">
                                        <i class="far fa-times"></i>
                                    </span>
                                </span>

                                <div style="display: inline-block; position: relative">
                                    <input
                                        v-model="recipient_keyword"
                                        type="text"
                                        id="flyte-recipient-keyword"
                                        class="form-control px-1 mb-2"
                                        v-on:focus="searchRecipient(true)"
                                        v-on:blur="focusRecipientKeyword(false)"
                                        style="width: 280px; max-width: calc(100% - 40px)"
                                    >
                                    <button
                                        v-if="recipient_keyword"
                                        type="button"
                                        class="btn btn-secondary btn-sm"
                                        v-b-tooltip.hover
                                        title="Add recipient"
                                        @click="addRecipient()"
                                        :disabled="sending"
                                        style="padding-top: 1px; padding-bottom: 1px; position: absolute; top: 0"
                                    >
                                        <i class="far fa-plus"></i>
                                    </button>
                                </div>
                            </div>

                            <div
                                class="dropdown-menu flyte-recipient-dropdown"
                                style="width: 280px; max-height: 230px; overflow-y: auto"
                            >
                                <div v-for="item in recipient_results" :key="item.id">
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        @click.prevent="addRecipient(item)"
                                    >
                                        {{ item.first_name }} {{ item.last_name }}
                                        <p class="small mb-1">{{ item.email }}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="flyte-message">Message</label>
                            <textarea
                                v-model="message"
                                id="flyte-message"
                                class="form-control"
                                :readonly="sending"
                                style="height: 120px"
                            ></textarea>
                        </div>
                        <div>
                            <label for="flyte-link-expires">Expires</label>
                            <div class="input-group">
                                <b-form-datepicker
                                    v-model="link_expires"
                                    id="flyte-link-expires"
                                    class="form-control"
                                    start-weekday="1"
                                    locale="en-GB"
                                    :min="tomorrow()"
                                    :hideHeader="true"
                                    :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                    :disabled="sending"
                                ></b-form-datepicker>
                                <div class="input-group-append">
                                    <button
                                        class="btn btn-light dropdown-toggle date-presets-button"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :disabled="(updating_link_expiry_date || sending)"
                                    >
                                        Preset periods
                                        <i
                                            class="fas fa-chevron-down d-none d-xl-inline-block"
                                            style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                        ></i>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a
                                            v-for="preset in link_expiry_date_presets"
                                            :key="preset.value"
                                            class="dropdown-item"
                                            href="#"
                                            @click.prevent="setDate('link_expires', preset.value)"
                                        >{{ preset.text }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="$root.hasPermission('flyte_access')" class="modal-footer" :style="modalFooterStyle()">
            <div v-if="uploading" class="progress-display" v-html="uploadProgress()"></div>

            <span class="button-holder" :style="addMoreFilesStyle()">
                <button type="button" class="btn btn-secondary" id="flyte-add-more-files" :disabled="sending">
                    <i class="far fa-cloud-upload-alt mr-1"></i>
                    Add more files
                </button>
            </span>

            <span v-if="!sending">
                <span>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="onSubmit()"
                        :disabled="(uploading || (uploaded_files.length == 0))"
                    >
                        <i class="far fa-paper-plane mr-1"></i> Send {{ filesCount() }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Send {{ filesCount() }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                uploading: false,
                upload_progress_message: false,
                sending: false,
                sent: false,
                uploaded_files: [],
                upload_progress: {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                },
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: true,
                    chunkSize: 6000000,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    maxFilesize: (10 * 1000 * 1000 * 1000),
                    autoProcessQueue: true,
                    timeout: 0,
                    clickable: '#flyte-add-files, #flyte-add-more-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                recipients: [],
                message: null,
                link_expires: null,
                recipient_keyword: '',
                block_recipient_keyword_auto_process: false,
                recipient_results: [],
                keypress_time: 0,
                updating_link_expiry_date: false,
                link_expiry_date_presets: [
                    {
                        value: '1d',
                        text: 'In 1 day'
                    },
                    {
                        value: '7d',
                        text: 'In 1 week'
                    },
                    {
                        value: '14d',
                        text: 'In 2 weeks'
                    },
                    {
                        value: '1m',
                        text: 'In 1 month'
                    },
                    {
                        value: 'never',
                        text: 'Never expires'
                    }
                ]
            }
        },
        mounted () {
            //
        },
        watch: {
            recipient_keyword: function (val) {
                this.$root.unHighlightErrors('flyte-recipients');
                if (val) {
                    this.searchRecipient(true);

                    return null;
                }
                $('.flyte-recipient-dropdown').dropdown('hide');
            },
            sent: function (val) {
                if (val) {
                    $('#flyte-form-modal > div').removeClass('modal-xl');

                    return null;
                }
                $('#flyte-form-modal > div').addClass('modal-xl');
            }
        },
        methods: {
            resetForm: function () {
                if (!this.$root.hasPermission('flyte_access')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.$parent.message = {
                    type: null,
                    title: '',
                    text: ''
                };
                this.errors = false;
                this.uploading = false;
                this.upload_progress_message = false;
                this.sending = false;
                this.sent = false;
                this.uploaded_files = [];
                this.upload_progress = {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                };
                this.recipients = [];
                this.message = null;
                this.link_expires = null;
                this.recipient_keyword = '';
                this.block_recipient_keyword_auto_process = false;
                if (this.$refs.flyteDropzone) {
                    this.$refs.flyteDropzone.removeAllFiles();
                }
                this.setDate('link_expires', '7d');
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                if (this.uploaded_files.length == 0) {
                    this.errors = 'Please add at least 1 file';

                    return null;
                }

                if (this.uploading) {
                    this.showPleaseWaitMessage();

                    return null;
                }

                this.errors = false;
                this.sending = true;

                let files = [];
                this.uploaded_files.forEach((item) => {
                    files.push({
                        filename: item.filename,
                        file_type: item.file_type,
                        file_size: item.file_size,
                        temp_file: item.temp_file
                    });
                });

                let data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    },
                    type: 'email',
                    subject: 'You have been sent a download link from ' + this.$root.client.client_name,
                    recipients: this.recipients,
                    flyte_files: files
                };
                if (this.recipient_keyword) {
                    data.recipients.push({email: this.recipient_keyword});
                }
                if (this.message) {
                    data.message = this.message;
                }
                if (this.link_expires) {
                    data.link_expires = this.link_expires;
                }

                /**
                 * Send share request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/share';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = 'An email with a download link has now been sent to your recipient(s).';
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#flyte-form-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // show error
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#flyte-' + key + '-label').addClass('text-danger');
                                $('#flyte-' + key).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            showPleaseWaitMessage: function () {
                this.$parent.message = {
                    type: null,
                    title: 'Attach file(s)',
                    loading: true,
                    text: '<div style="text-align: center">Please wait until all files are uploaded</div>'
                };
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            filesUploaded: function () {
                let count = 0;
                this.uploaded_files.forEach((file, index) => {
                    if (file.temp_file) {
                        count++;
                    }
                });

                return count;
            },
            doNothing: function () {
                return false;
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', this.$root.getUploadFileId(file));
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if ((this.uploaded_files.length == 0) || (progress > 99.9)) {
                    return null;
                }
                let bytesTotal = 0;
                let bytesSentTotal = 0;
                this.uploaded_files.forEach((item) => {
                    bytesTotal += item.file_size;
                    if (item.id == file.upload.uuid) {
                        if (isNaN(bytesSent)) {
                            bytesSent = file.size * (progress / 100);
                        }
                        item.upload.progress = progress;
                        item.upload.bytes_sent = bytesSent;
                        bytesSentTotal += bytesSent;
                        $('#upload-info' + item.id)
                            .html('Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                        $('#upload-progress' + item.id + ' > div').css('width', progress + '%');
                    } else if (item.upload.progress == 100) {
                        bytesSentTotal += item.file_size;
                    }
                });
                this.upload_progress.progress = ((bytesTotal * bytesSentTotal) > 0)
                    ? ((bytesSentTotal / bytesTotal) * 100) : 0;
                this.upload_progress.bytes_total = bytesTotal;
                this.upload_progress.bytes_sent = bytesSentTotal;
            },
            onFileUploaded: function (file, response) {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                // Find file.
                let itemIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == file.upload.uuid) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.uploaded_files[itemIndex].upload.progress = 100;
                    this.uploaded_files[itemIndex].upload.bytes_sent = file.size;
                    $('#upload-progress' + file.upload.uuid + ' > div').css('width', '100%');
                    if (!this.uploaded_files[itemIndex].preview_image) {
                        this.uploaded_files[itemIndex].preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + file.upload.uuid).html(
                        this.uploaded_files[itemIndex].preview_image ? 'Finalising upload...' : 'Previewing...'
                    );
                    this.getUploadInfo(this.uploaded_files[itemIndex].upload_id);
                    this.$refs.flyteDropzone.removeFile(file);
                }
                // If there are more files process queue??

            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Find file.
                    let itemIndex = -1;
                    vm.uploaded_files.forEach((item, index) => {
                        if (item.upload_id == response.data.file_id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        return null;
                    }

                    // Check size.
                    if (response.data.file_size < vm.uploaded_files[itemIndex].file_size) {
                        vm.$parent.message = {
                            type: 'error',
                            title: 'Upload failed',
                            text: 'Sorry it looks like the file <strong style="overflow-wrap: anywhere">'
                                + vm.uploaded_files[itemIndex].filename
                                + '</strong> did not upload correctly. Please try again.'
                        };
                        $('#message-modal').modal('show');
                        $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
                        vm.removeUploadedFile(vm.uploaded_files[itemIndex].id, true);

                        return null;
                    }

                    vm.uploaded_files[itemIndex].temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.uploaded_files[itemIndex].id).html('');

                    if (response.data.preview) {
                        vm.uploaded_files[itemIndex].preview_image = response.data.preview.src;
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                let allUploaded = true;
                this.uploaded_files.forEach((item) => {
                    if (!item.temp_file) {
                        allUploaded = false;
                    }
                });
                if (!allUploaded) {
                    return null;
                }
                this.uploading = false;
                this.upload_progress.progress = 0;
                this.$parent.message = {
                    type: null,
                    title: '',
                    text: ''
                };
                if ($('#message-modal').hasClass('show')) {
                    $('#message-modal').modal('hide');
                    this.$parent.resetModalBackdrop();
                }
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    this.errors = 'Something went wrong, please try again';
                }
                console.log(file, message);
            },
            onDragOver: function (event) {
                $('#flyte-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#flyte-dropzone').removeClass('dz-drag-highlight');
            },
            onFileAdded: function (file) {
                if (this.sending) {
                    this.$refs.flyteDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (file.size == 0) {
                    this.$refs.flyteDropzone.removeFile(file);
                    if (
                        (appComponent.message.title == 'Damaged file detected')
                        || (appComponent.message.title == 'Maximum upload size exceeded')
                    ) {
                        appComponent.message.text += '<br><br><strong style="overflow-wrap: anywhere">' + file.name
                            + '</strong> appears to be damaged and has not been uploaded.';
                        $('#message-modal').modal('show');
                        $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                        return null;
                    }
                    appComponent.message = {
                        type: 'error',
                        title: 'Damaged file detected',
                        text: '<strong style="overflow-wrap: anywhere">' + file.name
                            + '</strong> appears to be damaged and has not been uploaded.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.flyteDropzone.removeFile(file);
                    if (
                        (appComponent.message.title == 'Damaged file detected')
                        || (appComponent.message.title == 'Maximum upload size exceeded')
                    ) {
                        appComponent.message.text += '<br><br>The maximum size of each file you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of <strong style="overflow-wrap: anywhere">' + file.name + '</strong> is '
                            + this.$root.formatFileSize(file.size) + ' and has not been uploaded.';
                        $('#message-modal').modal('show');
                        $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                        return null;
                    }
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of each file you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of <strong style="overflow-wrap: anywhere">' + file.name + '</strong> is '
                            + this.$root.formatFileSize(file.size) + ' and has not been uploaded.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                let newFile = {
                    id: file.upload.uuid,
                    upload_id: this.$root.getUploadFileId(file),
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.uploaded_files.push(newFile);
                this.uploading = true;
                setTimeout(function () {
                    $('#upload-info' + file.upload.uuid).html('Preparing upload...');
                }, 500);
            },
            dzAddFilesStyle: function () {
                if (this.uploaded_files.length == 0) {
                    return 'display: block';
                }

                return 'display: none';
            },
            addMoreFilesStyle: function () {
                if (!this.uploading && !this.sending && (this.uploaded_files.length > 0)) {
                    return 'display: block';
                }

                return 'display: none';
            },
            modalFooterStyle: function () {
                if (!this.sent) {
                    return 'display: flex';
                }

                return 'display: none';
            },
            uploadProgress: function () {
                if (this.upload_progress_message) {
                    return this.upload_progress_message;
                }
                const percent = this.upload_progress.progress.toFixed(1);

                return 'Uploading ' + ((percent < 100) ? percent : '100') + '%';
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            removeUploadedFile: function(fileId, confirmed = false) {
                let fileIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == fileId) {
                        fileIndex = index;
                    }
                });
                if (fileIndex > -1) {
                    if (confirmed) {
                        const removeUploadFileId = (this.uploaded_files[fileIndex].upload.progress < 100) ? fileId
                            : null;

                        this.uploaded_files.splice(fileIndex, 1);

                        if (removeUploadFileId) {
                            this.$refs.flyteDropzone.getUploadingFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.flyteDropzone.removeFile(file);
                                }
                            });
                            this.$refs.flyteDropzone.getQueuedFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.flyteDropzone.removeFile(file);
                                }
                            });
                        }
                    
                        return null;
                    }

                    this.$parent.confirm_delete = {
                        title: 'Delete file',
                        text: 'Are you sure you want to delete: ' + this.uploaded_files[fileIndex].filename + '?',
                        component: this,
                        action: 'delete-file-' + fileId
                    };
                    $('#confirm-delete-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
                }
            },
            confirmDelete: function (action) {
                if (action.indexOf('delete-file-') > -1) {
                    const fileId = action.substr(12);
                    this.removeUploadedFile(fileId, true);
                }
            },
            focusRecipientKeyword: function (focus = true, delayed = false) {
                if (focus) {
                    $('#flyte-recipient-keyword').css('display', 'inline');
                    $('#flyte-recipient-keyword').focus();
                    var vm = this;
                    setTimeout(function () {
                        vm.block_recipient_keyword_auto_process = false;
                    }, 500);

                    return null;
                }

                if (!delayed) {
                    var vm = this;
                    setTimeout(function () {
                        vm.focusRecipientKeyword(false, true);
                    }, 500);

                    return null;
                }
                if (this.block_recipient_keyword_auto_process) {
                    return null;
                }
                if (this.recipient_keyword) {
                    this.addRecipient(null, false);
                }
                $('#flyte-recipient-keyword').css('display', 'none');
                $('.flyte-recipient-dropdown').dropdown('hide');
            },
            addRecipient: function (user = null, addNew = true) {
                if (!user && !this.recipient_keyword) {
                    return null;
                }
                const email = user ? user.email.trim() : this.recipient_keyword.trim();

                if ((email.length > 0) && !this.isRecipientAdded(email)) {
                    const recipient = {
                        email: email,
                        name: user ? user.first_name + ' ' + user.last_name : null,
                        user_id : user ? user.id : null
                    };
                    this.recipients.push(recipient);
                }

                this.recipient_keyword = '';
                this.recipient_results = [];
                setTimeout(function () {
                    $('.flyte-recipient-dropdown').dropdown('hide');
                }, 250);

                if (addNew) {
                    this.block_recipient_keyword_auto_process = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.focusRecipientKeyword();
                    }, 200);
                }
            },
            removeRecipient: function (recipient) {
                this.$root.unHighlightErrors('flyte-recipients');
                this.block_recipient_keyword_auto_process = true;
                let itemIndex = -1;
                this.recipients.forEach((item, index) => {
                    if (item == recipient) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.recipients.splice(itemIndex, 1);
                }
            },
            searchRecipient: function (delay = false) {
                if (!this.recipient_keyword) {
                    return null;
                }
                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.searchRecipient();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|!invited|kw::'
                    + btoa(this.recipient_keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.recipient_results = [];
                    response.data.users.forEach((item) => {
                        if ((item.email != vm.$root.user.email) && !vm.isRecipientAdded(item.email)) {
                            vm.recipient_results.push(item);
                        }
                    });
                    if (vm.recipient_results.length > 0) {
                        setTimeout(function () {
                            $('.flyte-recipient-dropdown').dropdown('show');
                        }, 200);

                        return null;
                    }
                    $('.flyte-recipient-dropdown').dropdown('hide');
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                });
            },
            isRecipientAdded: function (email) {
                if (!email || (this.recipients.length == 0)) {
                    return false;
                }
                let found = false;
                this.recipients.forEach((item) => {
                    if (item.email.toLowerCase() == email.toLowerCase()) {
                        found = true;
                    }
                });

                return found;
            },
            tomorrow: function () {
                const newDate = new Date();
                newDate.setDate(newDate.getDate() + 1);

                return newDate;
            },
            setDate: function (dateField, value) {
                this.updating_link_expiry_date = true;
                if (value == 'never') {
                    this[dateField] = null;
                } else {
                    const newDate = new Date();
                    if (value == '1d') {
                        newDate.setDate(newDate.getDate() + 1);
                    }
                    if (value == '7d') {
                        newDate.setDate(newDate.getDate() + 7);
                    }
                    if (value == '14d') {
                        newDate.setDate(newDate.getDate() + 14);
                    }
                    if (value == '1m') {
                        newDate.setMonth(newDate.getMonth() + 1);
                    }
                    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                    const day = ('0' + newDate.getDate()).slice(-2);
                    this[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                }
                this.updating_link_expiry_date = false;
            },
            filesCount: function () {
                if (this.uploaded_files.length == 0) {
                    return 'files';
                }
                if (this.uploaded_files.length == 1) {
                    return '1 file';
                }

                return this.uploaded_files.length + ' files';
            }
        }
    }
</script>
<style scoped>
    #flyte-dropzone {
        min-height: 300px;
    }

    #flyte-add-files {
        padding-top: 130px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    #flyte-add-more-files {
        position: absolute;
        left: 15px;
        bottom: 16px;
    }

    .progress-display {
        position: absolute;
        left: 15px;
        bottom: 10px;
        width: calc(100% - 100px);
    }

    #flyte-recipient-keyword {
        display: none;
        width: auto;
        height: 20px;
        border: none;
    }

    #flyte-recipient-keyword:focus {
        background-color: #f8f9fa;
    }
</style>
