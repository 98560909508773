<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="reserve-image-search-tooltip-modal-label">NEW FUNCTION</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                @click="onClose()"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <p>
                Click the Reverse image search icon
                <span class="mx-2" style="font-size: 27px"><i class="fa-light fa-camera-viewfinder"></i></span>
                in the search field to quickly discover visually similar images from across
                {{ $root.client.client_name }}.
            </p>
            <a href="#" @click.prevent="resetReverseImageSearch()">CLICK HERE</a> to try this new function.
        </div>
        <div v-if="!do_not_show_again" class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="doNotShowAgain()">
                Do not show again
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                do_not_show_again: false
            }
        },
        mounted () {
            const cookieName = this.$root.app.client_id + '_ristt' + this.$root.user.id;
            if (this.$cookie.get(cookieName)) {
                this.do_not_show_again = true;
            }
        },
        methods: {
            onClose: function () {
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');
                $('#reverse-image-search-tooltip-modal').css('display', 'none');
                $('#reverse-image-search-tooltip-modal').removeClass('show');
            },
            resetReverseImageSearch: function () {
                if (!this.$root.client.similar_assets_search) {
                    return null;
                }
                this.onClose();
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'reverse-image-search');
                if (component) {
                    component.reset();
                    setTimeout(function () {
                        $('#reverse-image-search-modal').modal('show');
                    }, 500);
                }
            },
            doNotShowAgain: function () {
                this.onClose();
                this.$root.setCookie(this.$root.app.client_id + '_ristt' + this.$root.user.id, 1, '1Y');
                var vm = this;
                setTimeout(function () {
                    vm.do_not_show_again = true;
                }, 200);
            }
        }
    }
</script>
